const wordEventName = [
  "AddComment",
  "ClipboardCopy",
  "ContentDeleted",
  "ContentInserted",
  "CurrentPageChange",
  "DocMapItemClick",
  "DocMapPanelChange",
  "DocumentSaveStatus",
  "DropdownListControlItemChange",
  "EditComment",
  "FontMissing",
  "Keypress",
  "ModelVisible",
  "RemoveComment",
  "OnBroadcast",
  "SecurityDocChangeReconnect",
  "SecurityDocPermissionChange",
  "SwitchCommandBar",
  "ViewModeChange",
  "WindowScrollChange",
  "WindowSelectionChange",
];
const pptEventName = [
  "ActiveSlideChange",
  "CommentModifyStatus",
  "DocumentSaveStatus",
  "Keypress",
  "LastSlideEnd",
  "MiniThumbnailVisibleInfo",
  "ModelVisible",
  "OnDiagramMount",
  "OnDiagramToggleBtns",
  "PresentationNewSlide",
  "PreviewScrollToBottom",
  "ReadonlySelectShapePosition",
  "SecurityDocChangeReconnect",
  "SecurityDocPermissionChange",
  "SlideChangeOperator",
  "SlideDelete",
  "SlideInkToolbarVisible",
  "SlideInkVisible",
  "SlideLaserPenInkPointsChanged",
  "SlideMediaChanged",
  "SlideMove",
  "SlidePlayerChange",
  // "SlideSelectionChanged",
  // "SlideShowBegin",
  // "SlideShowEnd",
  "SlideShowOnNext",
  "SlideShowOnPrevious",
  "SwitchCommandBar",
  "ThumbnailUpdate",
  "TriggerPlayEvent",
  "VideoFullscreenInfo",
];
const pdfEventName = [
  "CurrentPageChange", // 监听「当前页码改变」事件
  "DocumentSaveStatus",
  "Scroll", // 监听滚动事件
  "ZoomUpdated", // 监听缩放事件
  "StartPlay",
  "EndPlay",
  "PicSelectionChange", // 图片选区变更
  "TextSelectChange", // 文本选区变更
  "Keypress",
  "ModelVisible", // 模态框打开和关闭时触发
  "Swipe",
  "SwitchCommandBar",
];
const xlsEventName = [
  "Clipboard_Copy", // 监听剪切板“复制”事件
  "Worksheet_SelectionChange", // 监听选区改变事件
  "Worksheet_Activate", // 监听工作表切换
  "Worksheet_ScrollChange", // 监听用户自己的滚动画布行为
];
export function setWordListener(wps, callback) {
  wordEventName.forEach((element) => {
    wps.ApiEvent.AddApiEventListener(element, callback);
  });
}
export function removeWordListener(wps, callback) {
  wordEventName.forEach((element) => {
    wps.ApiEvent.RemoveApiEventListener(element, callback);
  });
}
export function setPPTListener(wps, callback) {
  pptEventName.forEach((element) => {
    wps.ApiEvent.AddApiEventListener(element, callback);
  });
}

export function removePPTListener(wps, callback) {
  pptEventName.forEach((element) => {
    wps.ApiEvent.RemoveApiEventListener(element, callback);
  });
}

export function setPDFListener(wps, callback) {
  pdfEventName.forEach((element) => {
    wps.ApiEvent.AddApiEventListener(element, callback);
  });
}

export function removePDFListener(wps, callback) {
  pdfEventName.forEach((element) => {
    wps.ApiEvent.RemoveApiEventListener(element, callback);
  });
}

export function setXLSListener(wps, callback) {
  xlsEventName.forEach((element) => {
    wps.ApiEvent.AddApiEventListener(element, callback);
  });
}

export function removeXLSListener(wps, callback) {
  xlsEventName.forEach((element) => {
    wps.ApiEvent.RemoveApiEventListener(element, callback);
  });
}
