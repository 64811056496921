<template>
  <!-- 文件列表 -->
  <!-- file.category!==2  让修改之后的复制文件不能下载
  colorType[0]+colorType[1]!== 'skkj' && colorType[0]+colorType[1]!== 'skkj'  在standsrdList中的集团课件不能下载 -->
  <div class="file-list__wrap" v-intro-autostart="false">
    <!-- 延迟我要修改  -->
    <div
      class="loading-test"
      v-if="loadingTest"
      v-loading="loadingTest"
      :element-loading-text="$t('el.common.elementLoading')"
    ></div>
    <!-- <div
      v-intro="'修改完名称，请点击确认！'"
      v-intro-position="'right'"
      v-intro-tooltip-class="'introjs-prepare introjs-step-6'"
      v-intro-step="6"
      class="introjs-dialog"></div> -->
    <div
      v-show="introjs && currentStep === 0"
      class="introjs-tooltipReferenceLayer-clone"
      style="width: 284px; height: 261px; top: 0px; left: 0px"
    >
      <div
        class="introjs-tooltip-clone introjs-prepare introjs-step-1 introjs-right"
        role="dialog"
        style="left: 294px"
      >
        <div class="introjs-tooltiptext">
          {{ $t("el.classroomWps.usePlatform") }}
        </div>
        <div class="introjs-arrow left" style="display: inherit"></div>
      </div>
    </div>
    <div
      class="file-list"
      ref="fileList"
      v-loading="fileLoading"
      :class="{ hide: listHide, 'file-list-sk': colorType[0] == 'sk' }"
      v-if="fileList && fileList.length"
    >
      <el-scrollbar class="el-scrollbar_x">
        <div
          v-intro="$t('el.classroomWps.usePersonal')"
          v-intro-position="'right'"
          v-intro-tooltip-class="'introjs-prepare introjs-step-2'"
          v-intro-step="1"
          class="el-scrollbar_x_content"
        >
          <!-- 标准资源 start -->
          <div class="standard" id="standard">
            <div
              v-if="
                fileObject.standardList &&
                fileObject.standardList.length &&
                colorType[1] !== 'bksp' &&
                colorType[1] !== 'ktsl'
              "
              slot="title"
              class="bk__center__title bk__center__titlefirst"
            >
              <div class="bk-headtitle">
                <div class="bk-headtitle__text">
                  {{ $t("el.classroomWps.groupResource") }} · ({{
                    fileObject.standardList.length
                  }})
                </div>
                <!-- 备课教案 -->
                <div
                  v-if="
                    fileObject.standardList.length > 0 &&
                    colorType[0] + colorType[1] == 'bkja'
                  "
                >
                  <div
                    class="bk-headtitle-onOrOff"
                    v-if="bkjarightstate"
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.less") }}
                  </div>
                  <div
                    class="bk-headtitle-onOrOff"
                    v-else
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.more") }}
                  </div>
                </div>
                <!-- 备课课件 -->
                <div
                  v-if="
                    fileObject.standardList.length > 0 &&
                    colorType[0] + colorType[1] == 'bkkj'
                  "
                >
                  <div
                    class="bk-headtitle-onOrOff"
                    v-if="bkkjrightstate"
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.less") }}
                  </div>
                  <div
                    class="bk-headtitle-onOrOff"
                    v-else
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.more") }}
                  </div>
                </div>
                <!-- 课堂资料 -->
                <div
                  v-if="
                    fileObject.standardList.length > 0 &&
                    colorType[0] + colorType[1] == 'bkktzl'
                  "
                >
                  <div
                    class="bk-headtitle-onOrOff"
                    v-if="bkktzlrightstate"
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.less") }}
                  </div>
                  <div
                    class="bk-headtitle-onOrOff"
                    v-else
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.more") }}
                  </div>
                </div>
                <!-- 授课课前预习 -->
                <div
                  v-if="
                    fileObject.standardList.length > 0 &&
                    colorType[0] + colorType[1] == 'skkqyx'
                  "
                >
                  <div
                    class="bk-headtitle-onOrOff"
                    v-if="skkqyxrightstate"
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.less") }}
                  </div>
                  <div
                    class="bk-headtitle-onOrOff"
                    v-else
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.more") }}
                  </div>
                </div>
                <!-- 授课课件 -->
                <div
                  v-if="
                    fileObject.standardList.length > 0 &&
                    colorType[0] + colorType[1] == 'skkj'
                  "
                >
                  <div
                    class="bk-headtitle-onOrOff"
                    v-if="skkjrightstate"
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.less") }}
                  </div>
                  <div
                    class="bk-headtitle-onOrOff"
                    v-else
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.more") }}
                  </div>
                </div>
                <!-- 授课作业 -->
                <div
                  v-if="
                    fileObject.standardList.length > 0 &&
                    colorType[0] + colorType[1] == 'skzy'
                  "
                >
                  <div
                    class="bk-headtitle-onOrOff"
                    v-if="skzyrightstate"
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.less") }}
                  </div>
                  <div
                    class="bk-headtitle-onOrOff"
                    v-else
                    @click="unfold(colorType[0] + colorType[1], 1)"
                  >
                    {{ $t("el.classroomWps.more") }}
                  </div>
                </div>
              </div>
            </div>
            <template
              v-if="fileObject.standardList && fileObject.standardList.length"
            >
              <!-- groupResource:最多显示3个列表  groupResourcehidden:全部隐藏  groupResourceUnfold:显示全部 -->
              <!-- ...state: 列表全部true 折叠，false 展开 ;  bkkjrightstate:右上角收起true和展开false显示    -->
              <div
                id="idgroupResource"
                :class="{
                  groupResource:
                    (colorType[0] + colorType[1] == 'bkja'
                      ? bkjarightstate && bkjastate
                      : false) ||
                    (colorType[0] + colorType[1] == 'bkkj'
                      ? bkkjrightstate && bkkjstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skkqyx'
                      ? skkqyxrightstate && skkqyxstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skkj'
                      ? skkjrightstate && skkjstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'bkktzl'
                      ? bkktzlrightstate && bkktzlstate
                      : false),
                  groupResourcehidden:
                    (colorType[0] + colorType[1] == 'bkja'
                      ? !bkjarightstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'bkkj'
                      ? !bkkjrightstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skkqyx'
                      ? !skkqyxrightstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skkj'
                      ? !skkjrightstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skzy'
                      ? !skzyrightstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skzy'
                      ? !skzyrightstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'bkktzl'
                      ? !bkktzlrightstate
                      : false),
                  groupResourceUnfold:
                    (colorType[0] + colorType[1] == 'bkja'
                      ? !bkjastate
                      : false) ||
                    (colorType[0] + colorType[1] == 'bkkj'
                      ? !bkkjstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skkqyx'
                      ? !skkqyxstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skkj'
                      ? !skkjstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skzy'
                      ? !skzystate
                      : false) ||
                    (colorType[0] + colorType[1] == 'bkktzl'
                      ? !bkktzlstate
                      : false),
                  bkspktsl: colorType[1] == 'bksp' || colorType[1] == 'ktsl',
                }"
              >
                <div
                  v-for="(file, index) in slicelist(
                    colorType[0] + colorType[1],
                    fileObject.standardList,
                    1
                  )"
                  :key="index"
                  :class="{
                    jumpclass: file === currentFile,
                    active: file === currentFile,
                    bk: file === currentFile && colorType[0] == 'bk',
                  }"
                  class="file-list__item firstFile"
                  @click="onClick(file)"
                >
                  <div class="file-list__operate">
                    <div class="file-list__boutique" v-if="file.isGood">
                      <img :src="boutique" alt="" />
                    </div>
                    <div class="file-list__type">
                      <img :src="file.resourceUrl | getFileType" alt="" />
                    </div>
                    <div class="file-list__descript">
                      <div class="file-list__name">
                        {{ spliteString(file.resourceName) }}
                      </div>
                      <div class="file-list__infomation">
                        <div class="file-list__schoolName">
                          {{ file.resourceSchoolName }}
                        </div>
                        <div v-if="file.author || file.author.length !== 0">
                          -
                        </div>
                        <div class="file-list__authName">
                          {{ file.author }}
                        </div>
                      </div>
                      <div
                        class="file-list__gooddown"
                        v-if="file !== currentFile"
                      >
                        <div
                          v-if="
                            fileObject.download === 0 &&
                            fileObject.isPrintDownLoad &&
                            colorType[0] + colorType[1] !== 'bkkj' &&
                            colorType[0] + colorType[1] !== 'skkj'
                          "
                          class="file-list__down"
                          @click="exportFile(file, 1)"
                          :class="{ skdown: colorType[0] == 'sk' }"
                        >
                          <div>{{ setNumberfun(file.downloadCount) }}</div>
                        </div>
                        <div
                          v-if="
                            fileObject.download === 1 &&
                            colorType[0] + colorType[1] !== 'bkkj' &&
                            colorType[0] + colorType[1] !== 'skkj'
                          "
                          class="file-list__downno"
                        >
                          <div>{{ setNumberfun(file.downloadCount) }}</div>
                        </div>
                        <div
                          @click.stop="likeFile(file, 1, $event)"
                          v-if="colorType[0] == 'bk'"
                          :class="{
                            skstyle: colorType[0] == 'sk',
                            'file-list__good': file.likeStatus == 0,
                            'file-list__good-act': file.likeStatus == 1,
                          }"
                        >
                          <img
                            class="like-active-icon"
                            :src="file.likeStatus == 1 ? bkgoodimg : nogoodimg"
                            alt=""
                          />
                          <div>{{ setNumberfun(file.likeCount) }}</div>
                        </div>
                        <div
                          v-if="colorType[0] == 'sk'"
                          @click.stop="likeFile(file, 1, $event)"
                          :class="{
                            skstyle: colorType[0] == 'sk',
                            'file-list__good': file.likeStatus == 0,
                            skstyleed: file.likeStatus == 1,
                          }"
                        >
                          <img
                            class="like-active-icon"
                            :src="file.likeStatus == 1 ? skgoodimg : nogoodimg"
                            v-if="colorType[0] == 'sk'"
                            alt=""
                          />
                          <div>{{ setNumberfun(file.likeCount) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="file-list__change" v-if="file === currentFile">
                    <div style="min-width: 80px">
                      <div
                        v-loading="loadMouse"
                        v-if="
                          (colorType[1] == 'kj' ||
                            colorType[1] == 'ja' ||
                            colorType[1] == 'ktzl') &&
                          file.isOwner == 0 &&
                          [
                            'ppt',
                            'pptx',
                            'PPT',
                            'PPTX',
                            'doc',
                            'docx',
                            'DOC',
                            'DOCX',
                            'pdf',
                            'PDF',
                            'xls',
                            'xlsx',
                            'XLS',
                            'XLSX',
                          ].indexOf(file.format) !== -1
                        "
                        @click.stop="
                          fileReNameAndCopy(true, (renamestate = true))
                        "
                        @mouseover="mouseOver(true, index, 'standard')"
                        @mouseleave="mouseOver(false)"
                        :class="[
                          colorType[0] == 'sk' ? 'sk-class' : 'bk-class',
                          colorType[0] == 'sk' &&
                          hoverIndexStandard === index &&
                          hoverClass
                            ? 'sk-bg-hover'
                            : colorType[0] == 'bk' &&
                              hoverIndexStandard === index &&
                              hoverClass
                            ? 'bk-bg-hover'
                            : '',
                          file.likeStatus == 1 ? 'already-like' : '',
                        ]"
                      >
                        {{ $t("el.classroomWps.myModify") }}
                      </div>
                    </div>
                    <div class="file-list__gooddown">
                      <div
                        v-if="
                          fileObject.download === 0 &&
                          colorType[0] + colorType[1] !== 'bkkj' &&
                          colorType[0] + colorType[1] !== 'skkj' &&
                          fileObject.isPrintDownLoad
                        "
                        class="file-list__down"
                        @click="exportFile(file, 1)"
                        :class="{ skdown: colorType[0] == 'sk' }"
                      >
                        <div>
                          {{ $t("el.classroomWps.download") }}
                          {{ setNumberfun(file.downloadCount) }}
                        </div>
                      </div>
                      <div
                        v-if="
                          fileObject.download === 1 &&
                          colorType[0] + colorType[1] !== 'bkkj' &&
                          colorType[0] + colorType[1] !== 'skkj'
                        "
                        class="file-list__downno"
                        @click="nodownload"
                      >
                        <div>
                          {{ $t("el.classroomWps.download") }}
                          {{ setNumberfun(file.downloadCount) }}
                        </div>
                      </div>
                      <div
                        @click.stop="likeFile(file, 1, $event)"
                        v-if="colorType[0] == 'bk'"
                        :class="{
                          skstyle: colorType[0] == 'sk',
                          'file-list__good': file.likeStatus == 0,
                          'file-list__good-act': file.likeStatus == 1,
                        }"
                      >
                        <img
                          class="like-active-icon"
                          :src="file.likeStatus == 1 ? bkgoodimg : nogoodimg"
                          alt=""
                        />
                        <div>
                          {{ $t("el.classroomWps.like") }}
                          {{ setNumberfun(file.likeCount) }}
                        </div>
                      </div>
                      <div
                        v-if="colorType[0] == 'sk'"
                        @click.stop="likeFile(file, 1, $event)"
                        :class="{
                          skstyle: colorType[0] == 'sk',
                          'file-list__good': file.likeStatus == 0,
                          skstyleed: file.likeStatus == 1,
                        }"
                      >
                        <img
                          class="like-active-icon"
                          :src="file.likeStatus == 1 ? skgoodimg : nogoodimg"
                          v-if="colorType[0] == 'sk'"
                          alt=""
                        />
                        <div>
                          {{ $t("el.classroomWps.like") }}
                          {{ setNumberfun(file.likeCount) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="bottoninfo"
                    @click.stop="jumpJa(file, file.relatedType)"
                    v-if="file.relatedName && file.relatedType == 'JA'"
                  >
                    {{ $t("el.classroomWps.supportingLessonPlan") }} -
                    <span class="bottonInfoLine">{{
                      spliteString(file.relatedName)
                    }}</span>
                  </div>
                  <div
                    class="bottoninfo"
                    @click.stop="jumpJa(file, file.relatedType)"
                    v-if="file.relatedName && file.relatedType == 'KJ'"
                  >
                    {{ $t("el.classroomWps.supportingCourseware") }} -
                    <span class="bottonInfoLine">{{
                      spliteString(file.relatedName)
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                @click="unfoldAll(colorType[0] + colorType[1], 1)"
                class="checkoutall"
                v-if="
                  fileObject.standardList.length > 3 &&
                  ((colorType[0] + colorType[1] == 'bkja'
                    ? bkjastate
                    : false) ||
                    (colorType[0] + colorType[1] == 'bkkj'
                      ? bkkjstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skkqyx'
                      ? skkqyxstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'bkktzl'
                      ? bkktzlstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skkj'
                      ? skkjstate
                      : false) ||
                    (colorType[0] + colorType[1] == 'skzy'
                      ? skzystate
                      : false) ||
                    (colorType[0] + colorType[1] == 'bkktzl'
                      ? bkktzlstate
                      : false))
                "
              >
                <img src="@/assets/images/icon/icon_list_morenew.png" alt="" />
                {{ $t("el.classroomWps.viewAll") }}
              </div>
            </template>
          </div>
          <!-- 标准资源 end -->
          <div
            v-intro="$t('el.classroomWps.sharetoSchool')"
            v-intro-position="'right'"
            v-intro-tooltip-class="'introjs-prepare introjs-step-3'"
            v-intro-step="2"
          >
            <!-- 学校资源 start-->
            <div
              class="bk__center bk_center_box schoolbox"
              id="bk__center"
              v-if="colorType[1] == 'kj' || colorType[1] == 'ja'"
            >
              <!-- <el-collapse
                v-model="collapseValue"
                @change="handleChange"
                v-intro="'查看学校资源， 可进行二次编辑'"
                v-intro-position="'right'"
                v-intro-tooltip-class="'introjs-prepare introjs-step-5'"
                v-intro-step="3">
                <el-collapse-item name="1"> -->
              <div slot="title" class="schooltitle">
                <!-- <span class="bk__center__text">学校资源</span> -->
                <div class="bk-headtitle">
                  <div class="bk-headtitle__text">
                    {{ $t("el.classroomWps.schoolResource") }} · ({{
                      fileObject &&
                      fileObject.collectiveList &&
                      fileObject.collectiveList.length
                    }})
                  </div>
                  <!-- 教案 -->
                  <div
                    v-if="
                      fileObject &&
                      fileObject.collectiveList &&
                      fileObject.collectiveList.length > 0 &&
                      colorType[0] + colorType[1] == 'bkja'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="bkjaschrightstate"
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                  <!-- 课件 -->
                  <div
                    v-if="
                      fileObject &&
                      fileObject.collectiveList &&
                      fileObject.collectiveList.length > 0 &&
                      colorType[0] + colorType[1] == 'bkkj'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="bkkjschrightstate"
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                  <!-- 授课课前预习 -->
                  <div
                    v-if="
                      fileObject &&
                      fileObject.collectiveList &&
                      fileObject.collectiveList.length > 0 &&
                      colorType[0] + colorType[1] == 'skkqyx'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="skkqyxschrightstate"
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                  <!-- 授课课件 -->
                  <div
                    v-if="
                      fileObject &&
                      fileObject.collectiveList &&
                      fileObject.collectiveList.length > 0 &&
                      colorType[0] + colorType[1] == 'skkj'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="skkjschrightstate"
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                  <!-- 授课作业 -->
                  <div
                    v-if="
                      fileObject &&
                      fileObject.collectiveList &&
                      fileObject.collectiveList.length > 0 &&
                      colorType[0] + colorType[1] == 'skzy'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="skzyschrightstate"
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 2)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  fileObject &&
                  fileObject.collectiveList &&
                  fileObject.collectiveList &&
                  fileObject.collectiveList.length
                "
              >
                <div
                  id="idgroupResource2"
                  :class="{
                    groupResourcesch:
                      (colorType[0] + colorType[1] == 'bkja'
                        ? bkjaschrightstate && bkjaschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'bkkj'
                        ? bkkjschrightstate && bkkjschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkqyx'
                        ? skkqyxschrightstate && skkqyxschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkj'
                        ? skkjschrightstate && skkjschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skzy'
                        ? skzyschrightstate && skzyschstate
                        : false),
                    groupResourcehiddensch:
                      (colorType[0] + colorType[1] == 'bkja'
                        ? !bkjaschrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'bkkj'
                        ? !bkkjschrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkqyx'
                        ? !skkqyxschrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkj'
                        ? !skkjschrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skzy'
                        ? !skzyschrightstate
                        : false),
                    groupResourceUnfoldsch:
                      (colorType[0] + colorType[1] == 'bkja'
                        ? !bkjaschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'bkkj'
                        ? !bkkjschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkqyx'
                        ? !skkqyxschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkj'
                        ? !skkjschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skzy'
                        ? !skzyschstate
                        : false),
                  }"
                >
                  <div
                    v-for="(file, index) in slicelist(
                      colorType[0] + colorType[1],
                      fileObject.collectiveList,
                      2
                    )"
                    :key="index"
                    :class="{
                      jumpclass: file === currentFile,
                      active: file === currentFile,
                      bk: file === currentFile && colorType[0] == 'bk',
                      last: fileObject.collectiveList.length - 1 === index,
                    }"
                    class="file-list__item"
                    @click="onClick(file)"
                  >
                    <div class="file-list__operate">
                      <div class="file-list__type">
                        <img :src="file.resourceUrl | getFileType" alt="" />
                      </div>

                      <div class="file-list__descript">
                        <div class="file-list__name">
                          {{ spliteString(file.resourceName) }}
                        </div>
                        <div class="file-list__infomation">
                          <div class="file-list__schoolName">
                            {{ file.resourceSchoolName }}
                          </div>
                          <div>-</div>
                          <div class="file-list__authName">
                            {{ file.author }}
                          </div>
                        </div>
                        <div
                          class="file-list__gooddown"
                          v-if="file !== currentFile"
                        >
                          <div
                            class="file-list__down"
                            v-if="
                              fileObject.download === 0 &&
                              file.category !== 2 &&
                              fileObject.isPrintDownLoad
                            "
                            @click="exportFile(file, 2)"
                            :class="{ skdown: colorType[0] == 'sk' }"
                          >
                            <div>{{ setNumberfun(file.downloadCount) }}</div>
                          </div>
                          <div
                            class="file-list__downno"
                            v-if="
                              fileObject.download === 1 && file.category !== 2
                            "
                          >
                            <div>{{ setNumberfun(file.downloadCount) }}</div>
                          </div>
                          <div
                            @click.stop="likeFile(file, 2, $event)"
                            v-if="colorType[0] == 'bk'"
                            :class="{
                              skstyle: colorType[0] == 'sk',
                              'file-list__good': file.likeStatus == 0,
                              'file-list__good-act': file.likeStatus == 1,
                            }"
                          >
                            <img
                              class="like-active-icon"
                              :src="
                                file.likeStatus == 1 ? bkgoodimg : nogoodimg
                              "
                              alt=""
                            />
                            <div>{{ setNumberfun(file.likeCount) }}</div>
                          </div>
                          <div
                            v-if="colorType[0] == 'sk'"
                            @click.stop="likeFile(file, 2, $event)"
                            :class="{
                              skstyle: colorType[0] == 'sk',
                              'file-list__good': file.likeStatus == 0,
                              skstyleed: file.likeStatus == 1,
                            }"
                          >
                            <img
                              class="like-active-icon"
                              :src="
                                file.likeStatus == 1 ? skgoodimg : nogoodimg
                              "
                              v-if="colorType[0] == 'sk'"
                              alt=""
                            />
                            <div>{{ setNumberfun(file.likeCount) }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="file-list__change center_change"
                      v-if="file === currentFile"
                    >
                      <div style="min-width: 80px">
                        <div
                          v-loading="loadMouse"
                          v-if="colorType[1] == 'kj' && file.isOwner == 0"
                          @click.stop="
                            fileReNameAndCopy(true, (renamestate = true))
                          "
                          @mouseover="mouseOver(true, index, 'standard')"
                          @mouseleave="mouseOver(false)"
                          :class="[
                            colorType[0] == 'sk' ? 'sk-class' : 'bk-class',
                            colorType[0] == 'sk' &&
                            hoverIndexStandard === index &&
                            hoverClass
                              ? 'sk-bg-hover'
                              : colorType[0] == 'bk' &&
                                hoverIndexStandard === index &&
                                hoverClass
                              ? 'bk-bg-hover'
                              : '',
                            file.likeStatus == 1 ? 'already-like' : '',
                          ]"
                        >
                          {{ $t("el.classroomWps.myModify") }}
                        </div>
                      </div>
                      <div class="file-list__gooddown">
                        <div
                          class="file-list__down"
                          v-if="
                            fileObject.download === 0 &&
                            file.category !== 2 &&
                            fileObject.isPrintDownLoad
                          "
                          @click="exportFile(file, 2)"
                          :class="{ skdown: colorType[0] == 'sk' }"
                        >
                          <div>
                            {{ $t("el.classroomWps.download") }}
                            {{ setNumberfun(file.downloadCount) }}
                          </div>
                        </div>
                        <div
                          class="file-list__downno"
                          v-if="
                            fileObject.download === 1 && file.category !== 2
                          "
                          @click="nodownload"
                        >
                          <div>
                            {{ $t("el.classroomWps.download") }}
                            {{ setNumberfun(file.downloadCount) }}
                          </div>
                        </div>
                        <div
                          @click.stop="likeFile(file, 2, $event)"
                          v-if="colorType[0] == 'bk'"
                          :class="{
                            skstyle: colorType[0] == 'sk',
                            'file-list__good': file.likeStatus == 0,
                            'file-list__good-act': file.likeStatus == 1,
                          }"
                        >
                          <img
                            class="like-active-icon"
                            :src="file.likeStatus == 1 ? bkgoodimg : nogoodimg"
                            alt=""
                          />
                          <div>
                            {{ $t("el.classroomWps.like") }}
                            {{ setNumberfun(file.likeCount) }}
                          </div>
                        </div>
                        <div
                          v-if="colorType[0] == 'sk'"
                          @click.stop="likeFile(file, 2, $event)"
                          :class="{
                            skstyle: colorType[0] == 'sk',
                            'file-list__good': file.likeStatus == 0,
                            skstyleed: file.likeStatus == 1,
                          }"
                        >
                          <img
                            class="like-active-icon"
                            :src="file.likeStatus == 1 ? skgoodimg : nogoodimg"
                            v-if="colorType[0] == 'sk'"
                            alt=""
                          />
                          <div>
                            {{ $t("el.classroomWps.like") }}
                            {{ setNumberfun(file.likeCount) }}
                          </div>
                        </div>
                      </div>
                      <!-- <el-popover
                        v-if="file.isOwner === 0"
                        :placement="
                          file.isOwner == 0 && introjs
                            ? 'left-end'
                            : 'right-start'
                        "
                        :popper-class="
                          file.isOwner === 0 && introjs
                            ? 'el-popover-zIndex-hot popover-dropdown'
                            : 'popover-dropdown'
                        "
                        trigger="hover"
                        ref="collectiveItemPopover"
                      >
                        <div
                          v-if="file.isOwner == 0"
                          @click.stop="fileReNameAndCopy(true, file)"
                          class="dropdown-menu-item cmm xg"
                          :class="colorType[0]"
                        >
                          我要修改
                        </div> -->

                      <!-- <div
                          v-if="file.isOwner == 1"
                          @click.stop="unshareFile(file)"
                          class="dropdown-menu-item cmm xg"
                          :class="colorType[0]"
                        >
                          取消分享
                        </div> -->
                      <!-- <div
                          @click.stop="exportFile(file)"
                          class="dropdown-menu-item cmm xg"
                          :class="colorType[0]"
                        >
                          {{ $t("el.common.download") }}
                        </div> -->
                      <!-- <span
                          class="dropdown-action dropdown-action-flex"
                          slot="reference"
                        >
                          <img
                            src="@/assets/images/icon/icon_list_more@2x.png"
                          />
                          <div class="dropdown-action-more">更多</div>
                        </span>
                      </el-popover> -->
                      <!-- <div
                        class="file-list__edit"
                        @click.stop="editFile(file)"
                        v-if="
                          file.isOwner == 1 &&
                          colorType[1] == 'kj' &&
                          file.reviewState != 0
                        "
                      >
                        修改
                      </div> -->
                      <!-- </div> -->
                    </div>
                    <div
                      class="bottoninfo"
                      @click.stop="jumpJa(file, file.relatedType)"
                      v-if="file.relatedName && file.relatedType == 'JA'"
                    >
                      {{ $t("el.classroomWps.supportingLessonPlan") }} -
                      <span class="bottonInfoLine">{{
                        spliteString(file.relatedName)
                      }}</span>
                    </div>
                    <div
                      class="bottoninfo"
                      @click.stop="jumpJa(file, file.relatedType)"
                      v-if="file.relatedName && file.relatedType == 'KJ'"
                    >
                      {{ $t("el.classroomWps.supportingCourseware") }} -
                      <span class="bottonInfoLine">{{
                        spliteString(file.relatedName)
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="checkoutall"
                  @click="unfoldAll(colorType[0] + colorType[1], 2)"
                  v-if="
                    fileObject &&
                    fileObject.collectiveList &&
                    fileObject.collectiveList.length > 3 &&
                    ((colorType[0] + colorType[1] == 'bkja'
                      ? bkjaschstate
                      : false) ||
                      (colorType[0] + colorType[1] == 'bkkj'
                        ? bkkjschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkqyx'
                        ? skkqyxschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkj'
                        ? skkjschstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skzy'
                        ? skzyschstate
                        : false))
                  "
                >
                  <img
                    src="@/assets/images/icon/icon_list_morenew.png"
                    alt=""
                  />{{ $t("el.classroomWps.viewAll") }}
                </div>
              </template>
              <template v-else>
                <div class="not-data">{{ $t("el.common.noResources") }}</div>
              </template>
              <!-- </el-collapse-item>
              </el-collapse> -->
            </div>

            <!-- 学校资源 end -->

            <!-- 个人资源 start -->
            <div class="my bk__center">
              <div
                slot="title"
                class="bk__center__title bk__center__titlefirst"
              >
                <!-- <span
                  class="bk__center__text"
                  v-if="colorType[1] !== 'bksp' && colorType[1] !== 'ktsl'"
                  >我的资源</span
                >-->
                <div
                  class="bk-headtitle"
                  v-if="colorType[1] !== 'bksp' && colorType[1] !== 'ktsl'"
                >
                  <div class="bk-headtitle__text">
                    {{ $t("el.classroomWps.myResourse") }} · ({{
                      fileObject.personalList && fileObject.personalList.length
                    }})
                  </div>
                  <!-- 教案 -->
                  <div
                    v-if="
                      fileObject.personalList &&
                      fileObject.personalList.length > 0 &&
                      colorType[0] + colorType[1] == 'bkja'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="bkjaperrightstate"
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                  <!-- 课件 -->
                  <div
                    v-if="
                      fileObject.personalList &&
                      fileObject.personalList.length > 0 &&
                      colorType[0] + colorType[1] == 'bkkj'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="bkkjperrightstate"
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                  <!-- 课堂资料 -->
                  <div
                    v-if="
                      fileObject.personalList &&
                      fileObject.personalList.length > 0 &&
                      colorType[0] + colorType[1] == 'bkktzl'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="bkktzlperrightstate"
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                  <!-- 授课课前预习 -->
                  <div
                    v-if="
                      fileObject.personalList &&
                      fileObject.personalList.length > 0 &&
                      colorType[0] + colorType[1] == 'skkqyx'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="skkqyxperrightstate"
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                  <!-- 授课课件 -->
                  <div
                    v-if="
                      fileObject.personalList &&
                      fileObject.personalList.length > 0 &&
                      colorType[0] + colorType[1] == 'skkj'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="skkjperrightstate"
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                  <!-- 授课作业 -->
                  <div
                    v-if="
                      fileObject.personalList &&
                      fileObject.personalList.length > 0 &&
                      colorType[0] + colorType[1] == 'skzy'
                    "
                  >
                    <div
                      class="bk-headtitle-onOrOff"
                      v-if="skzyperrightstate"
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.less") }}
                    </div>
                    <div
                      class="bk-headtitle-onOrOff"
                      v-else
                      @click="unfold(colorType[0] + colorType[1], 3)"
                    >
                      {{ $t("el.classroomWps.more") }}
                    </div>
                  </div>
                </div>
              </div>
              <template
                v-if="fileObject.personalList && fileObject.personalList.length"
              >
                <div
                  id="idgroupResource3"
                  :class="{
                    groupResourcehidden:
                      (colorType[0] + colorType[1] == 'bkja'
                        ? !bkjaperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'bkkj'
                        ? !bkkjperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkqyx'
                        ? !skkqyxperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkj'
                        ? !skkjperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skzy'
                        ? !skzyperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'bkktzl'
                        ? !bkktzlperrightstate
                        : false),
                    groupResourceUnfold:
                      (colorType[0] + colorType[1] == 'bkja'
                        ? bkjaperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'bkkj'
                        ? bkkjperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkqyx'
                        ? skkqyxperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skkj'
                        ? skkjperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'skzy'
                        ? skzyperrightstate
                        : false) ||
                      (colorType[0] + colorType[1] == 'bkktzl'
                        ? bkktzlperrightstate
                        : false),
                  }"
                >
                  <div
                    v-for="(file, index) in fileObject.personalList"
                    :key="index"
                    :class="{
                      jumpclass: file === currentFile,
                      share: file === currentShareFile,
                      active: file === currentFile,
                      bk: file === currentFile && colorType[0] == 'bk',
                      last: fileObject.personalList.length - 1 === index,
                      first: index === 0,
                    }"
                    ref="personalItem"
                    class="file-list__item"
                    @click="onClick(file)"
                  >
                    <!-- <div class="file-list__tips">{{ $t("el.schoolResourceManage.toAudit") }}</div> -->
                    <div
                      :class="{
                        othertip: colorType[0] == 'bk',
                        othertips: colorType[0] == 'sk',
                      }"
                      v-if="file.reviewState == 0 || file.reviewState == 1"
                    >
                      <span v-if="file.reviewState == 0">{{
                        $t("el.classroomWps.verification")
                      }}</span>
                      <span v-if="file.reviewState == 1">{{
                        $t("el.classroomWps.tobemodified")
                      }}</span>
                      <span v-if="file.reviewState == 2">{{
                        $t("el.classroomWps.passed")
                      }}</span>
                    </div>

                    <div class="file-list__operate">
                      <div class="file-list__type">
                        <img :src="file.resourceUrl | getFileType" alt="" />
                      </div>

                      <div class="file-list__descript">
                        <div class="file-list__name">
                          <!-- <el-tag size="mini">
                          <div class="text">我的</div>
                        </el-tag> -->
                          {{ spliteString(file.resourceName) }}
                        </div>
                        <div class="file-list__infomation">
                          <div class="file-list__schoolName">
                            {{ file.resourceSchoolName }}
                          </div>
                          <div>-</div>
                          <div class="file-list__authName">
                            {{ file.author }}
                          </div>
                        </div>
                        <!-- <div class="file-list__like">
                        <span class="author-icon"></span>
                        <span>作者 {{ file.author }}</span>
                      </div> -->
                      </div>
                    </div>
                    <div class="file-list__handle" v-if="file === currentFile">
                      <div class="file-list__dosomething">
                        <el-popover
                          placement="right-start"
                          popper-class="popover-dropdown"
                          trigger="hover"
                        >
                          <div
                            class="dropdown-menu"
                            v-if="file.reviewState !== 0"
                          >
                            <div
                              class="dropdown-menu-item cmm"
                              :class="colorType[0]"
                              @click.stop="
                                fileReNameAndCopy(false, (renamestate = false))
                              "
                            >
                              {{ $t("el.classroomWps.rename") }}
                            </div>
                            <div
                              class="dropdown-menu-item cmm"
                              :class="colorType[0]"
                              v-if="
                                fileObject.download === 0 &&
                                file.category !== 2 &&
                                fileObject.isPrintDownLoad
                              "
                              @click.stop="exportFile(file, 3)"
                            >
                              {{ $t("el.classroomWps.download") }}
                            </div>
                            <div
                              class="dropdown-menu-item cmm"
                              :class="colorType[0]"
                              @click.stop="uploadFileBtns(file.resourceId)"
                            >
                              {{ $t("el.classroomWps.reupload") }}
                            </div>
                            <div
                              class="dropdown-menu-item sc"
                              @click.stop="handleDelete(file)"
                            >
                              {{ $t("el.common.delete") }}
                            </div>
                          </div>
                          <div
                            class="dropdown-menu"
                            v-if="file.reviewState === 0"
                          >
                            <div
                              class="dropdown-menu-item cmm"
                              :class="colorType[0]"
                              @click.stop="replace(file.packageId)"
                            >
                              {{ $t("el.classroomWps.backModify") }}
                            </div>
                            <div
                              class="dropdown-menu-item cmm"
                              :class="colorType[0]"
                              v-if="
                                fileObject.download === 0 &&
                                file.category !== 2 &&
                                fileObject.isPrintDownLoad
                              "
                              @click.stop="exportFile(file, 3)"
                            >
                              {{ $t("el.classroomWps.download") }}
                            </div>
                          </div>
                          <div
                            class="dropdown-action dropdown-action-flex"
                            slot="reference"
                          >
                            <img
                              src="@/assets/images/icon/icon_list_more@2x.png"
                            />
                            <div class="dropdown-action-more">
                              {{ $t("el.classroomWps.mores") }}
                            </div>
                          </div>
                        </el-popover>
                        <div
                          class="file-list__edit"
                          :class="{
                            skedit: colorType[0] == 'sk',
                          }"
                          @click.stop="editFile(file)"
                          v-if="
                            file.isOwner == 1 &&
                            (colorType[1] == 'kj' ||
                              colorType[1] == 'ja' ||
                              colorType[1] == 'zy' ||
                              colorType[1] == 'kqyx' ||
                              colorType[1] == 'ktzl') &&
                            file.reviewState != 0 &&
                            [
                              'ppt',
                              'pptx',
                              'PPT',
                              'PPTX',
                              'doc',
                              'docx',
                              'DOC',
                              'DOCX',
                              'pdf',
                              'PDF',
                              'xls',
                              'xlsx',
                              'XLS',
                              'XLSX',
                            ].indexOf(file.format) !== -1
                          "
                        >
                          {{ $t("el.classroomWps.modify") }}
                        </div>
                        <div
                          @click="lookSuggest(file.packageId)"
                          class="file-list__suggest"
                          :class="{
                            sksuggest: colorType[0] == 'sk',
                          }"
                          v-if="
                            file.reviewState == 1 &&
                            (colorType[1] == 'kj' || colorType[1] == 'ja')
                          "
                        >
                          {{ $t("el.classroomWps.verifyOpinion") }}
                        </div>
                      </div>
                      <!-- click原本shareFile($event, file, index) -->
                      <el-popover
                        :popper-class="introjs ? 'el-popover-zIndex-hot' : ''"
                        placement="bottom"
                        trigger="hover"
                        v-loading="loadMouse"
                        @mousedown.stop
                        @click.native="
                          shareFiles($event, file, index, colorType[1])
                        "
                        v-if="
                          (file.reviewState == -1 || file.reviewState == 1) &&
                          (colorType[1] == 'kj' || colorType[1] == 'ja') &&
                          file.categoryPlatform == 0
                        "
                        @mouseover.native="mouseOver(true, index, 'my')"
                        @mouseleave.native="mouseOver(false)"
                        :class="[
                          colorType[0] == 'sk' ? 'sk-class' : 'bk-class',
                          colorType[0] == 'sk' &&
                          hoverIndexMy === index &&
                          hoverClass
                            ? 'sk-bg-hover'
                            : colorType[0] == 'bk' &&
                              hoverIndexMy === index &&
                              hoverClass
                            ? 'bk-bg-hover'
                            : '',
                        ]"
                      >
                        <div class="file-list__tip">
                          {{ $t("el.classroomWps.shareSchool1")
                          }}{{ file.grade }}、{{ file.subjects
                          }}{{ $t("el.classroomWps.shareSchool2") }}
                        </div>
                        <el-button slot="reference">{{
                          $t("el.classroomWps.shareToSchool")
                        }}</el-button>
                      </el-popover>

                      <!-- <div
                      v-loading="loadMouse"
                      @click.stop="shareFile($event,file,index)"
                      v-if="colorType[1] == 'kj' && (file.category == 1 || file.category == 2)"
                      @mouseover="mouseOver(true, index,'my')"
                      @mouseleave="mouseOver(false)"
                      :class="[colorType[0]== 'sk'? 'sk-class' : 'bk-class',
                        colorType[0]== 'sk'  && (hoverIndexMy === index) && hoverClass ? 'sk-bg-hover': colorType[0]== 'bk' && (hoverIndexMy === index) && hoverClass ? 'bk-bg-hover': '' ]"
                    >分享至学校</div>-->
                    </div>
                    <div
                      class="bottoninfo"
                      style="margin-top: 8px"
                      @click.stop="jumpJa(file, file.relatedType)"
                      v-if="file.relatedName && file.relatedType == 'JA'"
                    >
                      {{ $t("el.classroomWps.supportingLessonPlan") }} -
                      <span class="bottonInfoLine">{{
                        spliteString(file.relatedName)
                      }}</span>
                    </div>
                    <div
                      class="bottoninfo"
                      style="margin-top: 8px"
                      @click.stop="jumpJa(file, file.relatedType)"
                      v-if="file.relatedName && file.relatedType == 'KJ'"
                    >
                      {{ $t("el.classroomWps.supportingCourseware") }} -
                      <span class="bottonInfoLine">{{
                        spliteString(file.relatedName)
                      }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <div v-if="showUpload" class="footer">
                <div class="footer__inner" @click="uploadFileBtn">
                  <span class="footer__inner-icon"></span>
                  <!-- <img src="@/assets/images/icon/icon_list_upload@2x.png" /> -->
                  <label>
                    {{ uploadButtonText }}
                    <!-- 文件上传 -->
                  </label>
                  <!-- <i class="icon-details" @click.prevent="openAgreement"></i> -->
                </div>
              </div>
            </div>
            <!-- 个人资源 end -->
          </div>
        </div>
      </el-scrollbar>
      <!-- 折叠 -->
      <div
        v-show="!listHide && !isKj"
        class="file-list__toggle flex flex-m flex-c"
        @click="$emit('update:list-hide', true)"
      >
        {{ listHide ? $t("el.classroomWps.more") : $t("el.classroomWps.less") }}
        {{ $t("el.classroomWps.lists") }}
      </div>
      <div v-if="!!introjs" class="not-operate"></div>
    </div>
    <div v-if="fileList && fileList.length">
      <!-- v-if="['ppt','pptx', 'pdf', 'xls', 'xlsx','doc','docx','mp4','PPT','PPTX','PDF', 'XLS', 'XLSX','DOC','DOCX'].indexOf(currentFile.format)!==-1" -->
      <file-preview
        :file="currentFile"
        :not-data="!fileList.length"
        :course-id="courseId"
        :id="id"
        :colorType="colorType"
        :class="{ extension: listHide }"
        :need-check-resource="!this.introjs"
        :isEdit="isEdit"
        :fileChangedInC="fileChangedInC"
        @changeIsEdit="changeIsEdit"
        @onFullscreenChange="onFullscreenChange"
        :courseware="courseware"
        :isCompare="isCompare"
        ref="filePreview"
      ></file-preview>
    </div>
    <!-- 无数据开始 -->
    <div
      v-else
      class="no-data_box flex flex-column flex-m flex-c"
      :class="{ 'no-data_sk': colorType[0] == 'sk' }"
    >
      <img src="@/assets/images/pic_empty_course@2x.png" class="no-data_img" />
      <div class="no-data_text">{{ $t("el.common.noResources") }}</div>
      <div v-if="showUpload" class="footer">
        <div class="no-data_btn" @click="uploadFileBtn">
          <span class="no-data_icon"></span>
          <label>
            {{ uploadButtonText }}
            <!-- 文件上传 -->
          </label>
        </div>
      </div>
    </div>
    <!-- 无数据结束 -->

    <!-- 重命名弹窗 -->
    <el-dialog
      :title="
        renamestate
          ? $t('el.classroomWps.myModify')
          : $t('el.classroomWps.renameCourseware')
      "
      :visible.sync="reNameVisible"
      center
      width="464px"
      class="rename"
    >
      <div class="dialog__box">
        <label>
          <span>*</span>{{ $t("el.classroomWps.coursewareName") }}：
        </label>
        <div>
          <el-input
            type="textarea"
            v-model="reNameName"
            maxlength="50"
          ></el-input>
        </div>
      </div>
      <!-- <div class="dialog__box__link"  @click.stop="changeFile()">如何修改平台提供的课件？</div> -->
      <div slot="footer" class="dialog__footer">
        <el-button type="primary" size="small" @click="reNameMessageBox">{{
          $t("el.common.submit")
        }}</el-button>
        <el-button size="small" @click="reNameVisible = false">{{
          $t("el.common.cancel")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 删除弹窗 -->
    <el-dialog
      width="464px"
      class="delete"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="deleteVisible"
      custom-class="el-message-box--danger"
    >
      <div slot="title" class="red delete-header">
        <img src="@/assets/images/icon/msgbox_delete.png" />
        <span class="delete-header-text">{{
          $t("el.common.deleteConfirm")
        }}</span>
      </div>
      <div class="delete-content">
        {{ $t("el.classroomWps.confirmDeleteClass") }}
      </div>
      <span slot="footer" class="delete-footer">
        <el-button size="small" type="primary">{{
          $t("el.common.confirm")
        }}</el-button>
        <el-button size="small" @click="deleteVisible = false">{{
          $t("el.common.cancel")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 审核意见弹框 -->
    <el-dialog :visible.sync="suggestdialogVisible" width="480px" center>
      <div class="suggesttitle">{{ $t("el.classroomWps.verifyOpinion") }}</div>
      <div v-if="suggestInfo.length > 0">
        <div
          class="suggestdia"
          v-for="(item, index) in suggestInfo"
          :key="index"
        >
          <div class="suggest">
            <div class="suggest-head">
              <div class="suggest-head-people">
                {{ $t("el.classroomWps.verifier") }}：{{ item.operatorName }}
              </div>
              <div class="suggest-head-time">{{ item.createTime }}</div>
            </div>
            <div v-if="item.remarks != ''" class="suggest-content">
              {{ item.remarks }}
            </div>
            <div v-if="item.remarks == ''" class="suggest-content">
              {{ $t("el.classroomWps.noVerifyOpinion") }}
            </div>
          </div>
        </div>
      </div>
      <div class="noSuggest" v-if="suggestInfo.length == 0">
        <div class="noSuggest-center">
          <div class="noSuggest-img">
            <img :src="nosuggestimg" alt="" />
          </div>
          <div class="noSuggest-info">
            {{ $t("el.classroomWps.noVerifyOpinion") }}
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        :class="{ sksuggest: colorType[0] == 'sk' }"
      >
        <el-button
          size="small"
          type="primary"
          @click="suggestdialogVisible = false"
          >{{ $t("el.classroomWps.gotIt") }}</el-button
        >
      </span>
    </el-dialog>
    <!--修改弹窗 -->
    <!-- <el-dialog
      width="760px"
      class="change"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="changeVisible"
      custom-class="el-message-box--danger"
    >
      <el-scrollbar class="el-scrollbar_x">
        <div class="change-container">
          <div class="change-content">如何修改平台提供的课件</div>
          <div class="change-item">
            <div class="item-title">1、点击【我要修改】：将会把平台课件复制一份为您的个人课件；</div>
            <img class="img" src="@/assets/images/pic_explain01@2x.png" alt="海亮教育" title="海亮教育" />
          </div>
          <div class="change-item">
            <div class="item-title">2、选中复制出的个人课件，点击课件左上角的【在浏览器中编辑】，即可修改课件内容；</div>
            <img class="img" src="@/assets/images/pic_explain02@2x.png" alt="海亮教育" title="海亮教育" />
          </div>
          <div class="change-item">
            <div class="item-title">3、修改完成点击【查看-阅读视图】或右下角的【阅读视图】图标，方可保存，并回到阅读模式。</div>
            <img class="img" src="@/assets/images/pic_explain03@2x.png" alt="海亮教育" title="海亮教育" />
          </div>
        </div>
      </el-scrollbar>
    </el-dialog> -->
    <!-- 提示暂无权限 -->
    <el-dialog
      :title="$t('el.classroomWps.uploading')"
      width="464px"
      center
      class="el-dialog--loading"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="uploadVisible"
      @close="stopUpload"
    >
      <div class="el-dialog__content t-c">
        <!-- <img src="@/assets/images/icon/icon_loding.gif" />
        <div>{{ $t("el.classroomWps.uploaded") }}：{{ rate }}</div> -->
        <el-progress
          type="circle"
          :percentage="rate"
          :status="~~rate === 100 ? 'success' : null"
        ></el-progress>
      </div>
    </el-dialog>
    <input
      v-if="renderFile"
      id="file"
      type="file"
      style="display: none"
      ref="file"
      :accept="accept"
      @change="uploadFile"
    />

    <!-- 引导标题 -->
    <div v-if="introjs" class="introjs-title">
      {{ $t("el.classroomWps.howtoTitle") }}
    </div>
    <!-- 引导操作按钮 -->
    <div v-if="introjs" class="introjs-btn-group">
      <el-button
        size="small"
        class="introjs-btn introjs-btn--next"
        @click="handleIntrojsNext"
        >{{
          currentStep === 2
            ? $t("el.classroomWps.howtoTitle")
            : $t("el.common.nextStep")
        }}</el-button
      >
    </div>
    <!-- 分享至学校,配套教案 -->
    <el-dialog
      :visible.sync="sharecenterDialogVisible"
      width="480px"
      :before-close="cancelshare"
    >
      <div class="data-dialog-box">
        <div class="data-dialog-titleinfo">
          {{ $t("el.classroomWps.sharetoSchoolinfo") }}
        </div>
        <div class="copywriter">
          <img src="@/assets/images/icon/icon_box_tip@2x.png" alt="" />
          {{ $t("el.classroomWps.sharetoSchoolTips") }}
        </div>
        <div class="data-box">
          <div
            v-for="(item, index) in CanRelateResource"
            :key="index"
            @click="changeSelectInfo(index)"
          >
            <div
              class="data-dialog"
              :class="{ skdatadialog: colorType[0] == 'sk' }"
            >
              <div class="data-dialog-title">{{ item.resourceName }}</div>
              <img
                src="@/assets/images/icon/icon_checkbox_select@2x.png"
                class="data-checkoutimg"
                alt=""
                v-if="radiovalue == index && colorType[0] == 'bk'"
              />
              <img
                src="@/assets/images/icon/icon_checkbox_select@2x.png"
                class="data-checkoutimg"
                alt=""
                v-if="radiovalue == index && colorType[0] == 'sk'"
              />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="skdialog-footer">
        <div
          :class="{
            skbuttonbg: colorType[0] == 'sk',
            bkbuttonbg: colorType[0] == 'bk',
          }"
        >
          <el-button
            size="small"
            type="primary"
            @click="readyshare"
            v-if="radiovalue != null"
            >{{ $t("el.common.confirm") }}</el-button
          >
        </div>
        <div
          :class="{
            noskbuttonbg: colorType[0] == 'sk',
            nobkbuttonbg: colorType[0] == 'bk',
          }"
        >
          <el-button size="small" @click="cancelshare">{{
            $t("el.common.cancel")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 分享至学校,无配套教案 -->
    <el-dialog
      :title="$t('el.common.reminder')"
      :visible.sync="noDatadialogVisible"
      width="480px"
    >
      <div class="nodata-dialog">
        <div class="nodata-dialog-head">
          <i class="el-icon-warning"></i
          ><span v-if="colorType[1] !== 'ja'">{{
            $t("el.classroomWps.noLessonPlan")
          }}</span>
          <span v-if="colorType[1] !== 'kj'">{{
            $t("el.classroomWps.sharetoSchoolError")
          }}</span>
        </div>
        <div v-if="colorType[1] !== 'kj'" class="nodata-dialog-content">
          {{ $t("el.classroomWps.sharetoSchoolneed") }}
        </div>
        <div v-if="colorType[1] !== 'ja'" class="nodata-dialog-content">
          {{ $t("el.classroomWps.noLessonPlanCourseware") }}
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="noDatadialogVisible = false"
          >{{ $t("el.common.cancel") }}</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="noDatadialogVisible = false"
          >{{ $t("el.common.confirm") }}</el-button
        >
      </span> -->
      <div slot="footer" class="skdialog-footer">
        <div
          :class="{
            noskbuttonbgs: colorType[0] == 'sk',
            nobkbuttonbgs: colorType[0] == 'bk',
          }"
        >
          <el-button size="small" @click="noDatadialogVisible = false">{{
            $t("el.common.cancel")
          }}</el-button>
        </div>
        <div
          :class="{
            skbuttonbgs: colorType[0] == 'sk',
            bkbuttonbgs: colorType[0] == 'bk',
          }"
        >
          <el-button
            size="small"
            type="primary"
            @click="noDatadialogVisible = false"
            >{{ $t("el.common.confirm") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 退回修改弹框 -->
    <el-dialog title="提示" :visible.sync="alterdialogVisible" width="30%">
      <div class="nodata-dialog">
        <div class="nodata-dialog-head">
          <i class="el-icon-warning"></i
          ><span>{{ $t("el.classroomWps.backtoedit") }}</span>
        </div>
        <div class="nodata-dialog-content">
          {{ $t("el.classroomWps.backTips") }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="alterdialogVisible = false">{{
          $t("el.common.cancel")
        }}</el-button>
        <el-button size="small" type="primary" @click="ensurereplace">{{
          $t("el.common.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <input
      id="file"
      v-if="rechangestatus"
      type="file"
      style="display: none"
      ref="files"
      :accept="accept"
      @change="uploadFiles"
    />
  </div>
</template>

<script>
import { windowOpen } from "@/utils";
import { mapState } from "vuex";
import Vue from "vue";
import boutique from "@/assets/images/pic_list_boutique@2x.png";
import clickgood from "@/assets/images/icon/icon_list_good_normal@2x.png"; // 赞图标
import clickgooded from "@/assets/images/icon/icon_list_good_selected@2x.png"; // 已赞图标
import downicon from "@/assets/images/icon/icon_list_download_normal@2x.png"; // 下载图标
import downedicon from "@/assets/images/icon/icon_list_download_selected@2x.png"; // 已下载图标
import writeicon from "@/assets/images/icon/icon_list_modify_normal@2x.png";
import nosuggestimg from "@/assets/images/icon/pic_empty_resources@2xs.png";
// import boutique from "@/assets/images/pic_list_boutique@2x.png"
import _ from "lodash";
import {
  addPersonResource,
  cancelAxios, // 取消请求
  checkResource, // 上传前的文件检查
  delPersonResource,
  copyResource,
  reNameAndCopyResource,
  reSetName,
  like,
  share,
  unshare,
  checkSuggest,
  setreuploadResource,
  getCanRelateResource,
  getrecallCheck,
  addDownloadLikeRecord,
} from "@/api/classroom";
import FilePreview from "../components/file-preview.vue";
import { uploadWaterMarkDoc, getOssPolicy } from "@/api/upload";
import { getObsPolicy } from "@/api/classroom";
import { CancelToken, Cancel } from "axios";
import { matchSuffix } from "@/utils";
import "intro.js/introjs.css";
import VueIntro from "vue-introjs";
import nogoodimg from "@/assets/images/icon/icon_list_good_normal.svg"; // 赞图标
import bkgoodimg from "@/assets/images/icon/icon_list_good_selected.svg"; // 备课已赞图标
import skgoodimg from "@/assets/images/icon/icon_list_good_selected.svg"; // 授课已赞图标
import axios from "@/plugins/axios.js";
import { getUploadAuth } from "@/api/user";
Vue.use(VueIntro);

export default {
  name: "file-list",
  components: {
    FilePreview,
  },
  props: {
    /**
     * 知识点Id
     */
    id: {
      required: true,
    },
    courseId: {
      required: true,
    },
    /**
     * 是否是对照模式
     */
    isCompare: {
      type: Boolean,
      default: false,
    },
    /**
     * 文件列表
     */
    fileList: {
      type: Array,
      default: () => [],
    },
    /**
     * 文件列表 标准，集中，个人
     */
    fileObject: {
      type: Object,
      default: () => {},
    },
    /**
     * 课件对象
     */
    courseware: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否显示个人上传
     */
    showUpload: {
      type: Boolean,
      default: false,
    },
    /**
     * 显示上传按钮文本
     */
    uploadButtonText: {
      type: String,
      default: "上传个人教案",
    },
    /**
     * 加载状态
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * 文件选择过滤
     */
    // accept: {
    //   type: Array,
    //   default: () =>([])
    // },
    /**
     * 限制上传文件
     */
    formatLimit: {
      type: Array,
      default: () => [],
    },
    /**
     * 最大文件大小
     */
    maxSize: {
      type: Number,
      default: 200 * 1024 * 1024,
    },
    /**
     * 资源类型
     * [KJ，JA，KQYX，ZY]
     */
    type: {
      type: String,
      required: true,
    },
    /**
     * 预览的文件
     */
    file: {
      type: Object,
      default: null,
    },
    /**
     * 路由类型
     */
    routeType: {
      type: String,
      required: true,
    },
    /**
     * 列表隐藏
     */
    listHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bkkjstate: true, // 备课集团课件列表状态，true 折叠，false 展开
      bkkjrightstate: true, // 备课集团课件右上角收起true和展开false显示
      bkktzlstate: true, // 课堂资料集团课件右上角收起true和展开false显示
      bkktzlrightstate: true, // 课堂资料集团课件右上角收起true和展开false显示
      bkjastate: true,
      bkjarightstate: true,
      skkqyxstate: true,
      skkqyxrightstate: true,
      skkjstate: true,
      skkjrightstate: true,
      skzystate: true,
      skzyrightstate: true,
      bkkjschstate: true, // 备课学校课件列表状态，true 折叠，false 展开
      bkkjschrightstate: true, // 备课学校课件右上角收起true和展开false显示
      bkjaschstate: true,
      bkjaschrightstate: true,
      skkqyxschstate: true,
      skkqyxschrightstate: true,
      skkjschstate: true,
      skkjschrightstate: true,
      skzyschstate: true,
      syzyschrightstate: true,
      bkkjperrightstate: true, // 备课个人课件右上角收起true和展开false显示
      bkjaperrightstate: true,
      bkktzlperrightstate: true,
      skkqyxperrightstate: true,
      skkjperrightstate: true,
      skzyperrightstate: true,
      skgoodimg: skgoodimg,
      nogoodimg: nogoodimg,
      bkgoodimg: bkgoodimg,
      sharescoreid: null,
      timer: null,
      firstTime: true,
      imgState: false,
      checkLists: [0, 2, 3],
      nosuggestimg: nosuggestimg,
      diopackageId: "",
      alterdialogVisible: false, // 退回修改弹框
      noDatadialogVisible: false, // 获取可以配对的教案无数据
      sharecenterDialogVisible: false, // 分享至学校弹框
      CanRelateResource: [], // 获取可以配对的教案
      suggestdialogVisible: false, // 审核意见弹框
      boutique: boutique, // 精品图标
      clickgood: clickgood, // 赞图标
      clickgoods: clickgood, // 赞图标
      clickgooded: clickgooded, // 已赞图标
      downicon: downicon,
      downicons: downicon,
      downedicon: downedicon,
      currentFile: null, // 当前选择文件
      renderFile: true,
      rechangestatus: true,
      deleteVisible: false, // 确认删除提示框
      uploadVisible: false, // 上传提示框
      shardUpload: null, // 文件上传对象
      ossPercentrate: "0.0%", // 已上传率
      colorType: ["sk"],
      hoverClass: false,
      hoverIndexStandard: "",
      hoverIndexCenter: "",
      hoverIndexMy: "",
      // changeVisible: false,//如何修改课件弹窗
      loadMouse: false,
      // 列表隐藏
      // listHide: false,
      currentShareFile: null, // 当前分享文件
      reNameVisible: false, // 重命名弹窗
      reNameName: "", // 重命名名称
      showTip: false, // 重命名弹窗验证提示
      isCopy: false, // 是否是拷贝 false为重命名
      introConfig: {
        exitOnEsc: false, // 关闭esc进行退出
        exitOnOverlayClick: false, //  关闭点击空白处退出
        showStepNumbers: false, // 不显示步骤数
        overlayOpacity: 0.49, // 遮罩层透明度
        showBullets: false, // 不显示圆点进度条
        showButtons: false, // 不使用按钮操作
        tooltipClass: "introjs-prepare", // 自定义提示框样式
      },
      introjs: null, // 引导对象
      collapseValue: [], // 展开值
      renameVisible: false, // 文件重命名对话框
      currentStep: 0, // 当前步骤
      isEdit: false, // 是否是编辑wps
      loadingTest: false,
      fileLoading: false,
      suggestInfo: [],
      sharebott: {
        eventinfo: null,
        file: null,
        index: null,
      },
      radiovalue: null,
      replaceonload: null,
      renamestate: false,
      relevanceId: [],
      downrelevanceId: [],
      activestate: 1,
      acttype: null,
      acttype2: null,
      updatafirst: true,
      updatatimer: null,
      uploadParams: {},
      rate: 0,
      cancel: null,
      fileUploadObj: {}, // 点击上传时的数据
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user, // 用户信息
    }),
    /**
     * 类型名称
     */
    typeText() {
      return (
        {
          KJ: this.$t("el.classroomWps.courseware"),
          JA: this.$t("el.classroomWps.lessonPlan"),
          KQYX: this.$t("el.classroomWps.preview"),
          ZY: this.$t("el.classroomWps.homework"),
          ktzl: this.$t("el.classroomWps.classmaterials"),
        }[this.type] || ""
      );
    },
    accept() {
      return this.formatLimit.map((item) => `.${item}`);
    },
    /**
     * 是否是课件
     */
    isKj() {
      return (
        ["/classroomWps/bk-kj", "/classroomWps/sk-kj"].indexOf(
          this.$route.path
        ) !== -1
      );
    },
  },
  filters: {
    getFileType(resourceUrl) {
      let ppt = require("@/assets/images/icon/pic_format_ppt@2x.png");
      let doc = require("@/assets/images/icon/pic_format_word@2x.png");
      let excel = require("@/assets/images/icon/excel.png");
      let pdf = require("@/assets/images/icon/pdf.png");
      let mp4 = require("@/assets/images/icon/pic_format_mp4@2x.png");
      let otherfile = require("@/assets/images/icon/other-file.png");
      let type =
        resourceUrl.indexOf("pptx") > -1 ||
        resourceUrl.indexOf("ppt") > -1 ||
        resourceUrl.indexOf("PPTX") > -1 ||
        resourceUrl.indexOf("PPT") > -1
          ? ppt
          : resourceUrl.indexOf("doc") > -1 ||
            resourceUrl.indexOf("docx") > -1 ||
            resourceUrl.indexOf("DOC") > -1 ||
            resourceUrl.indexOf("DOCX") > -1
          ? doc
          : resourceUrl.indexOf("mp4") > -1 ||
            resourceUrl.indexOf("MP4") > -1 ||
            resourceUrl.indexOf("mov") > -1 ||
            resourceUrl.indexOf("MOV") > -1
          ? mp4
          : resourceUrl.indexOf("pdf") > -1 || resourceUrl.indexOf("PDF") > -1
          ? pdf
          : resourceUrl.indexOf("xls") > -1 ||
            resourceUrl.indexOf("xlsx") > -1 ||
            resourceUrl.indexOf("XLS") > -1 ||
            resourceUrl.indexOf("XLSX") > -1
          ? excel
          : otherfile;
      return type;
    },
  },
  watch: {
    fileList() {
      this.currentFile = null;
    },
    $route(Value) {
      this.colorType = Value.params.type.split("-");
    },
    loading(newVal) {
      this.fileLoading = newVal;
      this.changeScreenComputed();
    },
    /**
     * 双向绑定
     */
    currentFile() {
      this.$emit("change", this.currentFile);
    },
    file() {
      this.file &&
        (this.file.isPrintDownLoad = this.fileObject.isPrintDownLoad);
      this.currentFile = this.file;
    },
    introjs: {
      handler() {
        this.$emit("change-introjs", this.introjs);
      },
      immediate: true,
    },
    listHide(val) {
      // 收起列表 隐藏列表的时候 重新渲染距离
      if (val === false) {
        this.changeScreenComputed();
      }
    },
  },
  mounted() {
    this.colorType = this.$route.params.type.split("-");
    // this.unfoldState();
    // this.getinittype();
    // this.jumpheight(1);
    window.onresize = () => {
      if (!this.listHide) {
        this.changeScreenComputed();
      }
    };
  },
  beforeUpdate() {
    this.colorType = this.$route.params.type.split("-");
    // console.log("333")
  },
  beforeDestroy() {
    // 页面销毁，销毁引导对象
    this.introjs && this.introjs.exit();
    this.introjs = null;
    this.$emit("change-introjs", this.introjs);
    window.onresize = null;
  },
  methods: {
    changeScreenComputed() {
      // 更改屏幕宽度的时候，因为设置了最小宽度，重新计算课件预览区域的left
      this.$nextTick(() => {
        const width = this.$refs.fileList.clientWidth;
        this.$refs.filePreview.$el.style.left = `${width}px`;
      });
    },
    // 无下载权限的时候的提醒
    nodownload() {
      this.$message.error(this.$t("el.classroomWps.noDownloadPermisssion"));
    },
    // formate oss直传进度条
    formatProgress(percentage) {
      return this.$t("el.classroomWps.Uploading") + "\n" + `${percentage}%`;
    },
    fileChangedInC(newVal, oldVal) {
        this.$emit('fileChangedInC',newVal, oldVal);
    },
    /**
     * 初始选择菜单，自动跳转高度
     */
    jumpheight(type) {
      // console.log("type",type)
      if (type === 1) {
        setTimeout(() => {
          let listbox = document.querySelector(".file-list .jumpclass");
          // console.log("listbox",listbox)
          let app = document.querySelector(".file-list");
          listbox.setAttribute("id", "maodian");
          let aele = document.createElement("a");
          aele.href = "#maodian";
          app.insertBefore(aele, app.childNodes[0]);
          aele.setAttribute("id", "classboxmao");
          aele.click();
          aele.remove();
        }, 500);
      }
    },
    /**
     * 改变列表数据的长度
     * @type 当前状态
     * @data 需要操作的数据
     * @where 1，集团资源 2，学校资源 3，个人资源
     */
    slicelist(type, data, where) {
      if (where === 1) {
        switch (type) {
          case "bkja":
            if (this.bkjastate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "bkkj":
            if (this.bkkjstate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "skkqyx":
            if (this.skkqyxstate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "skkj":
            if (this.skkjstate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "skzy":
            if (this.skzystate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "bkktzl":
            if (this.bkktzlstate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "bkbksp":
            return data;

          case "bkktsl":
            return data;
        }
      } else if (where === 2) {
        switch (type) {
          case "bkja":
            if (this.bkjaschstate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "bkkj":
            if (this.bkkjschstate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "skkqyx":
            if (this.skkqyxschstate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "skkj":
            if (this.skkjschstate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "skzy":
            if (this.skzyschstate) {
              return data.slice(0, 3);
            } else {
              return data;
            }

          case "bkbksp":
            return data;

          case "bkktsl":
            return data;
        }
      }
    },
    /**
     * 点击查看全部
     */
    unfoldAll(type, where) {
      if (where === 1) {
        switch (type) {
          case "bkkj":
            this.bkkjstate = !this.bkkjstate;
            break;
          case "bkja":
            this.bkjastate = !this.bkjastate;
            break;
          case "skkqyx":
            this.skkqyxstate = !this.skkqyxstate;
            break;
          case "skkj":
            this.skkjstate = !this.skkjstate;
            break;
          case "skzy":
            this.skzystate = !this.skzystate;
            break;
          case "bkktzl":
            this.bkktzlstate = !this.bkktzlstate;
            break;
        }
      } else if (where === 2) {
        switch (type) {
          case "bkkj":
            this.bkkjschstate = !this.bkkjschstate;
            break;
          case "bkja":
            this.bkjaschstate = !this.bkjaschstate;
            break;
          case "skkqyx":
            this.skkqyxschstate = !this.skkqyxschstate;
            break;
          case "skkj":
            this.skkjschstate = !this.skkjschstate;
            break;
          case "skzy":
            this.skzyschstate = !this.skzyschstate;
            break;
        }
      }
    },
    /**
     * 收起和展开
     * @type 获取当前的选项 @where 1，集团资源 2，学校资源 3，个人资源
     */
    unfold(type, where) {
      if (where === 1) {
        switch (type) {
          case "bkkj":
            if (this.bkkjrightstate) {
              this.bkkjstate = false;
              this.bkkjrightstate = !this.bkkjrightstate;
            } else {
              this.bkkjstate = true;
              this.bkkjrightstate = !this.bkkjrightstate;
            }
            break;
          case "bkja":
            if (this.bkjarightstate) {
              this.bkjastate = false;
              this.bkjarightstate = !this.bkjarightstate;
            } else {
              this.bkjastate = true;
              this.bkjarightstate = !this.bkjarightstate;
            }
            break;
          case "skkqyx":
            if (this.skkqyxrightstate) {
              this.skkqyxstate = false;
              this.skkqyxrightstate = !this.skkqyxrightstate;
            } else {
              this.skkqyxstate = true;
              this.skkqyxrightstate = !this.skkqyxrightstate;
            }
            break;
          case "skkj":
            if (this.skkjrightstate) {
              this.skkjstate = false;
              this.skkjrightstate = !this.skkjrightstate;
            } else {
              this.skkjstate = true;
              this.skkjrightstate = !this.skkjrightstate;
            }
            break;
          case "skzy":
            console.log(this.skzyrightstate, "skzyrightstate");
            if (this.skzyrightstate) {
              this.skzystate = false;
              this.skzyrightstate = !this.skzyrightstate;
            } else {
              this.skzystate = true;
              this.skzyrightstate = !this.skzyrightstate;
            }
            break;
          case "bkktzl":
            console.log(this.bkktzlrightstate, "bkktzl");
            if (this.bkktzlrightstate) {
              this.bkktzlstate = false;
              this.bkktzlrightstate = !this.bkktzlrightstate;
            } else {
              this.bkktzlstate = true;
              this.bkktzlrightstate = !this.bkktzlrightstate;
            }
            break;
        }
      } else if (where === 2) {
        switch (type) {
          case "bkkj":
            if (this.bkkjschrightstate) {
              this.bkkjschstate = false;
              this.bkkjschrightstate = !this.bkkjschrightstate;
            } else {
              this.bkkjschstate = true;
              this.bkkjschrightstate = !this.bkkjschrightstate;
            }
            break;
          case "bkja":
            if (this.bkjaschrightstate) {
              this.bkjaschstate = false;
              this.bkjaschrightstate = !this.bkjaschrightstate;
            } else {
              this.bkjaschstate = true;
              this.bkjaschrightstate = !this.bkjaschrightstate;
            }
            break;
          case "skkqyx":
            if (this.skkqyxschrightstate) {
              this.skkqyxschstate = false;
              this.skkqyxschrightstate = !this.skkqyxschrightstate;
            } else {
              this.skkqyxschstate = true;
              this.skkqyxschrightstate = !this.skkqyxschrightstate;
            }
            break;
          case "skkj":
            if (this.skkjschrightstate) {
              this.skkjschstate = false;
              this.skkjschrightstate = !this.skkjschrightstate;
            } else {
              this.skkjschstate = true;
              this.skkjschrightstate = !this.skkjschrightstate;
            }
            break;
          case "skzy":
            if (this.skzyschrightstate) {
              this.skzyschstate = false;
              this.skzyschrightstate = !this.skzyschrightstate;
            } else {
              this.skzyschstate = true;
              this.skzyschrightstate = !this.skzyschrightstate;
            }
            break;
        }
      } else if (where === 3) {
        switch (type) {
          case "bkkj":
            this.bkkjperrightstate = !this.bkkjperrightstate;
            break;
          case "bkja":
            this.bkjaperrightstate = !this.bkjaperrightstate;
            break;
          case "skkqyx":
            this.skkqyxperrightstate = !this.skkqyxperrightstate;
            break;
          case "skkj":
            this.skkjperrightstate = !this.skkjperrightstate;
            break;
          case "skzy":
            this.skzyperrightstate = !this.skzyperrightstate;
            break;
          case "bkktzl":
            this.bkktzlperrightstate = !this.bkktzlperrightstate;
            break;
        }
      }
    },

    // 重新上传
    resetAddFile(file, resourceUrl) {
      setreuploadResource(this.replaceonload, {
        fileName: file.name,
        resourceSize: file.size,
        format: file.name.replace(/.+\./, ""),
        category: 1,
        knowledgeId: this.fileUploadObj.knowledgeId,
        resourceType: this.fileUploadObj.resourceType,
        resourceUrl,
      })
        .then((ele) => {
          if (ele.success) {
            this.$message.success(this.$t("el.classroomWps.uploadedSuccess"));
            this.$router.replace({
              path: this.$route.path,
              query: {
                ...this.$route.query,
              },
            });
            this.$emit("refresh", true); // 页面刷新
            this.uploadVisible = false;
          } else {
            this.$message.error(this.$t("el.classroomWps.uploadFail"));
          }
        })
        .catch((e) => {
          this.uploadVisible = false;
        });
    },
    // oss上传成功后插入表中
    addFile(file, resourceUrl) {
      addPersonResource({
        fileName: file.name,
        resourceSize: file.size,
        format: file.name.replace(/.+\./, ""),
        category: 1,
        knowledgeId: this.fileUploadObj.knowledgeId,
        resourceType: this.fileUploadObj.resourceType,
        resourceUrl,
        originTenantId: this.$route.query.originTenantId,
      })
        .then((ele) => {
          console.log("ele", ele);
          if (ele.success) {
            this.$message.success(this.$t("el.classroomWps.uploadedSuccess"));
            this.$router.replace({
              path: this.$route.path,
              query: {
                ...this.$route.query,
                resourceId: ele.data,
              },
            });
            this.$emit("refresh", true); // 页面刷新
            this.uploadVisible = false;
          } else {
            this.$message.error(this.$t("el.classroomWps.uploadFail"));
          }
        })
        .catch(() => {
          this.$message.error(this.$t("el.classroomWps.uploadFail"));
        });
    },
    // oss直传
    ossUpload(file, type) {
      if (file.type.indexOf("video") !== -1) {
        this.$message.info(this.$t("el.classroomWps.cannotVideo"));
        return;
      }
      this.uploadVisible = true;
      this.rate = 0;
      console.log("file", file);
      console.log("uploadParams", this.uploadParams);
      let formData = new FormData();
      formData.append("key", this.uploadParams.key);
      formData.append("policy", this.uploadParams.policy);
      formData.append("ossAccessKeyId", this.uploadParams.accessKeyId);
      formData.append("success_action_status", "200");
      formData.append("signature", this.uploadParams.signature);
      formData.append("dir", this.uploadParams.dir);
      formData.append("callback", this.uploadParams.callback);
      formData.append("file", file);
      let self = this;
      axios
        .post(this.uploadParams.host, formData, {
          cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            // self.cancel = self.createCancelMethod(c);
            self.cancel = c;
          }),
          onUploadProgress: (progressEvent) => {
            let complete =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            console.log("上传 " + complete);
            self.rate = complete;
          },
        })
        .then((res) => {
          if (res.success) {
            if (type === 1) {
              this.addFile(file, res.data);
            } else {
              this.resetAddFile(file, res.data);
            }
            // this.$message.success("文件上传成功");
            // this.$router.push({
            //   path: this.$route.path,
            //   query: {
            //     ...this.$route.query,
            //   },
            // });
            // this.$emit("refresh", true); //页面刷新
            // this.uploadVisible = false;
          } else {
            this.uploadVisible = false;
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          this.uploadVisible = false;
          console.log("error", error);

          this.$message.error(
            error.message || this.$t("el.classroomWps.uploadFail")
          );
        });
    },
    // obs直传
    obsUploadFile(file, type) {
      if (file.type.indexOf("video") !== -1) {
        this.$message.info(this.$t("el.classroomWps.cannotVideo"));
        return;
      }
      this.uploadVisible = true;
      this.rate = 0;
      let formData = new FormData();
      formData.append("key", this.uploadParams.key);
      formData.append("x-obs-acl", "private");
      formData.append("content-type", "multipart/form-data");
      formData.append("policy", this.uploadParams.policy);
      formData.append("signature", this.uploadParams.signature);
      formData.append("AccessKeyId", this.uploadParams.accessKeyId);
      formData.append("file", file);
      let self = this;
      axios
        .post(this.uploadParams.host, formData, {
          cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            // self.cancel = self.createCancelMethod(c);
            self.cancel = c;
          }),
          onUploadProgress: (progressEvent) => {
            let complete =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            console.log("上传 " + complete);
            self.rate = complete;
          },
        })
        .then(() => {
          const data = this.uploadParams.key;
          // 回调callback函数
          let decodeFunc = window.atob(this.uploadParams.callback);
          console.log("decodeFunc=====", JSON.parse(decodeFunc), file);
          decodeFunc = JSON.parse(decodeFunc);
          // this.getCallBackFunc(decodeFunc, file);
          if (type === 1) {
            this.addFile(file, data);
          } else {
            this.resetAddFile(file, data);
          }
        })
        .catch((error) => {
          this.uploadVisible = false;
          console.log("error", error);

          this.$message.error(
            error.message || this.$t("el.classroomWps.uploadFail")
          );
        });
    },
    // callback
    getCallBackFunc(obj, file) {
      let params = {
        filename: this.uploadParams.key,
        size: file.size,
        mimeType: file.type,
      };
      axios({
        url: "/obs/info/callback",
        method: "get",
        params: params,
      });
    },
    // 全屏回调
    onFullscreenChange(fullscreen) {
      this.$emit("onFullscreenChange", fullscreen);
    },
    /**
     * 文件上传
     */
    uploadFile(event) {
      console.log("event========", event);
      if (!event.target.files || !event.target.files.length)
        return this.$message.warning(this.$t("el.classroomWps.selectFile"));
      var file = event.target.files[0];
      this.resetFile(); // 重置表单

      // var reg = new RegExp(`(?<=\.)(${this.formatLimit.join('|')})$`);
      var sizeError = 0;
      var formatError = 0;
      var nullFile = 0;
      var suffix = this.$utils.getExtension(file.name);
      if (file.size > this.maxSize) {
        sizeError++;
      }
      if (
        this.formatLimit.length !== 0 &&
        this.formatLimit.indexOf(suffix) === -1
      ) {
        formatError++;
      }
      if (file.size <= 0) {
        nullFile++;
      }
      let wpsFormat = ["ppt", "pptx", "pdf", "xls", "xlsx", "doc", "docx"];
      if (wpsFormat.indexOf(suffix) > -1) {
        // 错误提示
        if (sizeError || formatError) {
          this.$message.warning(
            `${
              sizeError ? "文件最大" + this.maxSize / 1024 / 1024 + "M，" : ""
            }${
              formatError
                ? "仅允许上传" + this.formatLimit.join("、") + "格式，"
                : ""
            }请重新选择`
          );
          return;
        }
      }

      // 不允许上传空文件
      if (nullFile) {
        this.$message.warning(this.$t("el.classroomWps.filenoContent"));
        return;
      }
      checkResource({
        fileName: file.name,
        resourceSize: file.size,
        format: file.name.replace(/.+\./, ""),
        category: 1,
        knowledgeId: this.fileUploadObj.knowledgeId,
        resourceType: this.fileUploadObj.resourceType,
        originTenantId: this.$route.query.originTenantId,
      }).then((res) => {
        if (res.data === true) {
          let params = {
            fileName: file.name,
            xObsAcl: "private",
            source: "front",
          };
          this.obsUpload(1, file, params);
          // getOssPolicy(params)
          //   .then((ele) => {
          //     if (ele.success) {
          //       this.uploadParams = ele.data;
          //       this.ossUpload(file, 1);
          //     } else {
          //       this.$message.error(this.$t("el.classroomWps.uploadFail"));
          //     }
          //   })
          //   .catch(() => {
          //     this.$message.error(this.$t("el.classroomWps.uploadFail"));
          //   });
        }
      });
    },
    /**
     * obs直传
     * type  1:上传 2:重新上传
     * file 文件
     * params 入参 可能有fileName
     */
    obsUpload(type, file, params) {
      getObsPolicy(params)
        .then((ele) => {
          if (ele.success) {
            this.uploadParams = ele.data;
            this.obsUploadFile(file, type);
          } else {
            this.$message.error(this.$t("el.classroomWps.uploadFail"));
          }
        })
        .catch(() => {
          this.$message.error(this.$t("el.classroomWps.uploadFail"));
        });
    },
    /**
     * 开始引导
     */
    startIntrojs() {
      this.collapseValue = ["1"];
      this.$nextTick(() => {
        setTimeout(() => {
          this.introjs = this.$intro();
          this.introjs
            .setOptions(this.introConfig)
            .onchange(this.changeIntrojsStep)
            .oncomplete(() => {
              this.introjs = null;
              this.$emit("refresh");
              let popover = this.$refs.collectiveItemPopover[0];
              popover && popover.doClose();
            })
            .start();
        }, 400);
      });
    },
    /**
     * 引导步骤改变
     */
    changeIntrojsStep() {
      this.currentStep = this.introjs._currentStep;
    },
    /**
     * 引导下一步
     */
    handleIntrojsNext() {
      if (!this.introjs) {
        console.warn("未初始化引导对象");
        return;
      }
      switch (
        this.introjs._currentStep // 当前步骤
      ) {
        case 0: // 第二步，需要选中我的课件
          if (
            !(
              this.currentFile &&
              [1, 2].indexOf(this.currentFile.category) !== -1
            )
          ) {
            // 未选择我的课件
            // return this.$message({
            //   message: '请选择我上传的个人资源',
            //   type: 'info',
            //   customClass: 'el-messsage-custom'
            // });
            // 自动选择
            this.onClick(this.fileObject.personalList[0]);
            this.$nextTick(() => {
              this.introjs.refresh();
            });
          }
          break;
        case 1:
          if (this.fileObject.personalList[0]) {
            // 是否已经分享
            return this.shareFile(null, this.fileObject.personalList[0], 0);
          } else {
            let popover = this.$refs.collectiveItemPopover[0];
            popover && popover.doShow();
          }
          break;
        case 2:
          break;
        default:
          return;
      }
      this.introjs.nextStep();
    },
    /**
     * 拷贝资源
     */
    reNameAndCopyResource(params) {
      this.loadMouse = true;
      this.loadingTest = true;
      let mess = "";
      if (this.$route.params.type == "bk-ja") {
        mess = this.$t("el.classroomWps.turnPersonplan");
      }
      if (this.$route.params.type == "bk-kj") {
        mess = this.$t("el.classroomWps.turnPersonCourseware");
      }
      if (this.$route.params.type == "sk-kj") {
        mess = this.$t("el.classroomWps.turnPersonCourseware");
      }
      if (this.$route.params.type == "bk-ktzl") {
        mess = this.$t("el.classroomWps.turnPersonresource");
      }
      return reNameAndCopyResource(params)
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              type: "info",
              message: mess,
            });
            this.$router
              .replace({
                query: {
                  ...this.$route.query,
                  resourceId: res.data.newResourceId,
                },
              })
              .then(() => {
                var that = this;
                that.$emit("refresh");
                that.$refs.filePreview.isWantedit = false;
                that.loadingTest = false;
              });
          } else {
            this.$message({
              type: "error",
              message: res.data,
            });
          }
        })
        .finally(() => {
          this.loadingTest = false;
          this.loadMouse = false;
        });
    },
    /**
     * 重命名和拷贝文件对话框确认
     */
    reNameMessageBox() {
      if (this.reNameName.trim() === "") {
        this.$message({
          type: "info",
          message: this.$t("el.classroomWps.enterfileName"),
        });
        return;
      }
      this.reNameVisible = false;
      this.reNameParams.fileName = this.reNameName;
      this.reNameParams.originTenantId = this.$route.query.originTenantId;
      // 拷贝文件
      if (this.isCopy) {
        this.reNameAndCopyResource(this.reNameParams);
      } else {
        // 重命名
        reSetName(this.reNameParams)
          .then(() => {
            this.$emit("refresh", true);
          })
          .catch(() => {});
      }
    },
    /**
     * 重命名和拷贝文件
     */
    fileReNameAndCopy(isCopy, renamestate, file) {
      this.isCopy = isCopy;
      this.renamestate = renamestate;
      this.reNameVisible = true;
      this.reNameParams = {
        knowledgeId: this.id,
        resourceType: this.type,
      };
      if (file == undefined) {
        this.reNameName = this.spliteString(this.currentFile.resourceName);
        this.reNameParams.resourceId = this.currentFile.resourceId;
      } else {
        this.reNameName = this.spliteString(file.resourceName);
        this.reNameParams.resourceId = file.resourceId;
      }
    },
    setFile(file) {
      let params = {
        resourceId: file.resourceId,
        knowledgeId: this.id,
        resourceType: this.type,
      };
      this.loadMouse = true;
      copyResource(params)
        .then((res) => {
          if (res.status == 200) {
            this.loadMouse = false;
            this.$message({
              type: "info",
              message: this.$t("el.classroomWps.turnPersonCourseware"),
            });
            this.$emit("refresh");
          } else {
            this.loadMouse = false;
            this.$message({
              type: "error",
              message: res.data,
            });
          }
        })
        .catch(() => {});
    },
    // changeFile() {
    //   this.changeVisible = true;
    // },
    mouseOver(flag, index, type) {
      if (type == "standard") {
        this.hoverIndexStandard = index;
        this.hoverIndexCenter = "";
        this.hoverIndexMy = "";
      } else if (type == "center") {
        this.hoverIndexCenter = index;
        this.hoverIndexStandard = "";
        this.hoverIndexMy = "";
      } else if (type == "my") {
        this.hoverIndexMy = index;
        this.hoverIndexStandard = "";
        this.hoverIndexCenter = "";
      }
      this.hoverClass = flag;
    },
    /**
     * 点赞文件
     */
    likeFile(file, index, event) {
      if (file.likeStatus === 0) {
        file.likeStatus = 1;
        file.likeCount++;
        event.currentTarget.firstElementChild.classList.add("move");
      } else if (file.likeStatus === 1) {
        event.currentTarget.firstElementChild.classList.remove("move");
        file.likeStatus = 0;
        file.likeCount--;
        // document.documentElement.querySelector(".likeactive-icon img")
      }
      // event.currentTarget.firstElementChild.classList.remove("move");
      like(file.resourceId).then((res) => {
        // this.$emit("frensh", true);
        if (res.status == 200) {
          this.relevanceId = res.data.likeId.filter((dataid) => {
            return dataid != file.resourceId;
          }); // 获取除过本身的关联resourceId
          if (this.relevanceId.length == 0) {
            // 当没有关联id时
            // if (file.likeStatus === 0) {
            // setTimeout(() => {
            // file.likeStatus = 1;
            // file.likeCount++;
            // event.currentTarget.firstElementChild.classList.add("move");
            // event.currentTarget.firstElementChild.classList.remove("move");
            // }, 1000);
            // document.documentElement.querySelector(".likeactive-icon img").classList.add("move");//点赞动画
            // } else if (file.likeStatus === 1) {
            // file.likeStatus = 0;
            // file.likeCount--;
            // document.documentElement.querySelector(".likeactive-icon img")
            // }
            return;
          } else if (this.relevanceId.length > 0) {
            // 当存在关联id
            // if (file.likeStatus === 0) {
            // file.likeStatus = 1;
            // file.likeCount++;
            // document.documentElement.querySelector(".likeactive-icon img").classList.add("move");
            // } else if (file.likeStatus === 1) {
            // file.likeStatus = 0;
            // file.likeCount--;
            //  document.documentElement.querySelector(".likeactive-icon img").classList.remove("move");
            // }
            this.$emit(
              "changeNewnCount",
              this.relevanceId[0],
              index,
              res.data.likeCount,
              1
            );
          }
        } else {
          this.$message({
            type: "error",
            message: res.data,
          });
        }
      });
    },
    /**
     * 取消分享
     */
    cancelshare() {
      this.sharecenterDialogVisible = false;
      this.sharebott.eventinfo = null;
      this.sharebott.file = null;
      this.sharebott.index = null;
      this.radiovalue = null;
    },
    /**
     * 确定分享按钮
     */
    readyshare() {
      this.shareFile(
        this.sharebott.eventinfo,
        this.sharebott.file,
        this.sharebott.index
      );
      setTimeout(() => {
        this.sharecenterDialogVisible = false;
        this.sharebott.eventinfo = null;
        this.sharebott.file = null;
        this.sharebott.index = null;
        this.radiovalue = null;
      }, 0);
    },
    /**
     * 退回修改
     */
    replace(data) {
      this.diopackageId = data;
      this.alterdialogVisible = true;
    },
    /**
     * 确定退回修改
     */
    ensurereplace() {
      this.alterdialogVisible = false;
      let params = {
        packageId: this.diopackageId,
      };
      getrecallCheck(params).then((res) => {
        if (res.status == 200) {
          this.$emit("refresh", true); // 页面刷新
          this.currentShareFile = null;
          // eslint-disable-next-line no-undef
          executeAnimate(() => {
            this.$emit("refresh", true);
            this.$alert(this.$("el.classroomWps.fileRecall"), "", {
              confirmButtonText: this.$t("el.classroomWps.gotIt"),
            });
          });
        } else {
          this.$message({
            type: "error",
            message: res.data,
          });
        }
      });
    },
    /**
     * 点击分享至学校按钮
     */
    shareFiles($event, file, index, gettype) {
      this.sharebott.eventinfo = $event;
      this.sharebott.file = file;
      this.sharebott.index = index;
      this.sharescoreid = file.relatedId;
      let pardata = {
        knowledgeId: this.$route.query.id,
      };
      let type = "";
      if (gettype == "kj") {
        type = "JA";
      }
      if (gettype == "ja") {
        type = "KJ";
      }
      getCanRelateResource(type, pardata).then((res) => {
        this.CanRelateResource = res.data;
        // this.CanRelateResource = [
        //   {
        //     resourceId: 28,
        //     resourceName:
        //       "我是一个小教案测试长度玩儿的法国红酒复工后宏观环境回家看看绘笔江南刚好",
        //   },
        //   {
        //     resourceId: 29,
        //     resourceName: "我是一个小教案",
        //   },
        //   {
        //     resourceId: 30,
        //     resourceName: "我是一个小教案",
        //   },
        //   {
        //     resourceId: 202108387,
        //     resourceName: "我是一个小教案",
        //   },
        // ];
        if (this.sharescoreid) {
          for (let i = 0; i < this.CanRelateResource.length; i++) {
            if (this.sharescoreid == this.CanRelateResource[i].resourceId) {
              this.radiovalue = i;
              this.sharecenterDialogVisible = true;
              break;
            } else {
              this.radiovalue = 0;
              this.sharecenterDialogVisible = true;
            }
          }
        } else {
          if (this.CanRelateResource && this.CanRelateResource.length > 0) {
            // this.radiovalue = this.CanRelateResource[0].resourceId;
            this.radiovalue = 0;
            this.sharecenterDialogVisible = true;
          } else {
            this.noDatadialogVisible = true;
          }
        }
      });
    },
    /**
     * 分享时选择教案
     */
    changeSelectInfo(index) {
      this.radiovalue = index;
    },
    /**
     * 去掉文件名后缀方法
     *  @param {*} str 字符串
     */
    spliteString(str) {
      let n = null;
      if (str.indexOf(".") == -1) {
        n = str;
      } else {
        if (
          str.indexOf(".ppt") != -1 ||
          str.indexOf(".pptx") != -1 ||
          str.indexOf(".PPT") != -1 ||
          str.indexOf(".PPTX") != -1 ||
          str.indexOf(".doc") != -1 ||
          str.indexOf(".docx") != -1 ||
          str.indexOf(".DOC") != -1 ||
          str.indexOf(".DOCX") != -1 ||
          str.indexOf(".MP4") != -1 ||
          str.indexOf(".mp4") != -1 ||
          str.indexOf(".MOV") != -1 ||
          str.indexOf(".mov") != -1
        ) {
          n = str.substring(0, str.lastIndexOf("."));
        } else {
          n = str;
        }
      }
      return n;
    },
    /**
     *下载资源 节流1s处理
     * @param {*} row
     */
    exportFile(row, index) {
      let resourceName = row.resourceName;
      console.log(row);
      if (
        row.resourceName.indexOf(".ppt") !== -1 ||
        row.resourceName.indexOf(".pptx") !== -1 ||
        row.resourceName.indexOf(".PPT") !== -1 ||
        row.resourceName.indexOf(".PPTX") !== -1 ||
        row.resourceName.indexOf(".doc") !== -1 ||
        row.resourceName.indexOf(".docx") !== -1 ||
        row.resourceName.indexOf(".DOC") !== -1 ||
        row.resourceName.indexOf(".DOCX") !== -1 ||
        row.resourceName.indexOf(".MP4") !== -1 ||
        row.resourceName.indexOf(".mp4") !== -1 ||
        row.resourceName.indexOf(".MOV") !== -1 ||
        row.resourceName.indexOf(".mov") !== -1 ||
        row.resourceName.indexOf(".pdf") !== -1 ||
        row.resourceName.indexOf(".PDF") !== -1 ||
        row.resourceName.indexOf(".xls") !== -1 ||
        row.resourceName.indexOf(".XLS") !== -1 ||
        row.resourceName.indexOf(".xlsx") !== -1 ||
        row.resourceName.indexOf(".XLSX") !== -1 ||
        row.resourceName.indexOf(".mp3") !== -1 ||
        row.resourceName.indexOf(".MP3") !== -1
      ) {
        resourceName = row.resourceName;
      } else {
        resourceName = row.resourceName + "." + row.format;
      }
      let queryParams = {
        objectName: row.resourceUrl,
        displayName: resourceName,
        isDownload: 1,
      };
      if (this.firstTime) {
        // 第一次加载
        addDownloadLikeRecord(row.resourceId, 3)
          .then((data) => {
            this.$utils.download("/obs/info/download", queryParams, {
              method: "get",
            });
            this.downrelevanceId = data.data.downloadId.filter((dataid) => {
              return dataid != row.resourceId;
            }); // 获取除过本身的关联resourceId
            if (this.downrelevanceId.length > 0) {
              this.$emit("changeNewnCount", this.downrelevanceId, index, 1, 2);
            }
            row.downloadCount++;
          })
          .catch((data) => {
            this.$utils.download("/obs/info/download", queryParams, {
              method: "get",
            });
            this.downrelevanceId = data.data.downloadId.filter((dataid) => {
              return dataid != row.resourceId;
            }); // 获取除过本身的关联resourceId
            if (this.downrelevanceId.length > 0) {
              this.$emit("changeNewnCount", this.downrelevanceId, index, 1, 2);
            }
            row.downloadCount++;
            // this.$emit("refresh", true); //页面刷新
          });
        return (this.firstTime = false);
      }
      if (this.timer) {
        // 定时器正在执行中，跳过
        return;
      }
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.timer = null;
        addDownloadLikeRecord(row.resourceId, 3)
          .then((data) => {
            this.$utils.download("/obs/info/download", queryParams, {
              method: "get",
            });
            row.downloadCount++;
            this.downrelevanceId = data.data.downloadId.filter((dataid) => {
              return dataid != row.resourceId;
            }); // 获取除过本身的关联resourceId
            if (this.downrelevanceId.length > 0) {
              this.$emit("changeNewnCount", this.downrelevanceId, index, 1, 2);
            }
          })
          .catch((data) => {
            this.$utils.download("/obs/info/download", queryParams, {
              method: "get",
            });
            this.downrelevanceId = data.data.downloadId.filter((dataid) => {
              return dataid != row.resourceId;
            }); // 获取除过本身的关联resourceId
            if (this.downrelevanceId.length > 0) {
              this.$emit("changeNewnCount", this.downrelevanceId, index, 1, 2);
            }
            // this.$emit("refresh", true); //页面刷新
          });
      }, 1000);
    },

    /**
     * 获取文件格式
     * @param {*} url
     */
    getFormat(url) {
      let formatIndex = url.lastIndexOf(".");
      let format = "";
      if (formatIndex >= 0) {
        format = url.substring(formatIndex + 1);
      }
      return format;
    },
    /**
     * 分享文件
     */
    shareFile($event, file, index) {
      let pagetype = this.$route.params.type;
      let paramsinfo = {
        knowledgeId: Number(this.$route.query.id),
        jaResourceId: this.CanRelateResource[this.radiovalue].resourceId,
        kjResourceId: this.file.resourceId,
        originTenantId: this.$route.query.originTenantId,
      };
      if (pagetype == "bk-ja") {
        paramsinfo = {
          knowledgeId: Number(this.$route.query.id),
          jaResourceId: this.file.resourceId,
          kjResourceId: this.CanRelateResource[this.radiovalue].resourceId,
          originTenantId: this.$route.query.originTenantId,
        };
      }
      // var el = $event.currentTarget.parentElement.parentElement;
      var el = this.$refs.personalItem[index];
      var translateHeight =
        -(
          el.offsetTop -
          document.getElementById("standard").offsetHeight -
          40
        ) + "px";
      this.currentShareFile = this.fileObject.personalList[index];

      // 引导模式
      if (this.introjs && this.introjs._currentStep === 1) {
        this.currentShareFile = null;
        executeAnimate(() => {
          // 动画执行完成
          let file = this.fileObject.personalList.splice(index, 1);
          file[0] && this.fileObject.collectiveList.push(file[0]);
          this.$nextTick(() => {
            this.handleIntrojsNext();
          });
        });
      } else {
        share(paramsinfo).then((res) => {
          if (res.status == 200) {
            this.currentShareFile = null;
            this.$notify({
              title: this.$t("el.classroomWps.thanksShare"),
              message: this.$t("el.classroomWps.togetherShare"),
              type: "success",
            });
            this.$emit("refresh", true);
          } else {
            this.$message({
              type: "error",
              message: res.data,
            });
          }
        });
      }
      /**
       * 执行动画效果
       */
      function executeAnimate(end) {
        el.style.transition = "transform 0.5s linear 0s";
        el.style.transformOrigin = "80% top";
        el.style.transform = "translateY(" + translateHeight + ") scale(0)";
        setTimeout(() => {
          el.style = "";
          typeof end === "function" && end.call();
        }, 400);
      }
    },
    /**
     * 取消分享
     */
    unshareFile(file) {
      this.$confirm("", "", {
        center: true,
        customClass: "el-message-box--confirm",
        message: this.$t("el.classroomWps.cancelShare"),
      }).then(() => {
        unshare(file.resourceId).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t("el.classroomWps.cancelsuccess"));
            this.$emit("refresh", true);
          } else {
            this.$message({
              type: "error",
              message: res.data,
            });
          }
        });
      });
    },
    /**
     * 编辑模式
     */
    editFile(file) {
      if (this.currentFile.resourceId !== file.resourceId) {
        file.isPrintDownLoad = this.fileObject.isPrintDownLoad;
        this.currentFile = file;
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            resourceId: file.resourceId,
          },
        });
      }
      this.isEdit = true;
      this.$refs.filePreview.setpptreadoredit();
    },
    /**
     * 切换编辑模式 回到officeOnline
     */
    changeIsEdit(isEdit) {
      this.$emit("refresh", true);
      this.isEdit = isEdit;
    },
    /**
     * 学校展开
     */
    handleChange() {},
    async uploadFileBtn() {
      const { data } = await getUploadAuth();
      if (!data)
        return this.$message.info(
          this.$t("el.classroomWps.noUploadPermisssion")
        );
      // 判断浏览器是否支持FileReader
      if (typeof FileReader == "undefined" || typeof File == "undefined") {
        this.$message({
          type: "warning",
          message: this.$t("el.classroomWps.cannotBatch"),
        });
      } else {
        this.fileUploadObj = {
          knowledgeId: this.id,
          resourceType: this.type,
        };
        this.$refs.file.click();
      }
    },
    async uploadFileBtns(file) {
      const { data } = await getUploadAuth();
      if (!data)
        return this.$message.info(
          this.$t("el.classroomWps.noUploadPermisssion")
        );
      // 判断浏览器是否支持FileReader
      if (typeof FileReader == "undefined" || typeof File == "undefined") {
        this.$message({
          type: "warning",
          message: this.$t("el.classroomWps.cannotBatch"),
        });
      } else {
        this.fileUploadObj = {
          knowledgeId: this.id,
          resourceType: this.type,
        };
        this.replaceonload = file;
        this.$refs.files.click();
      }
    },
    /**
     * 重置file表单，解决重复选择同一个文件不触发change事件问题
     */
    resetFile() {
      this.renderFile = false;
      this.$nextTick(() => {
        this.renderFile = true;
      });
    },
    /**
     * 重置重新上传file表单，解决重复选择同一个文件不触发change事件问题
     */
    resetFiles() {
      this.rechangestatus = false;
      this.$nextTick(() => {
        this.rechangestatus = true;
      });
    },
    /**
     * 触发开始上传
     */
    trigger() {
      this.$refs.file && this.$refs.file.click();
    },
    toDecimal(x) {
      // 保留一位小数,无小数位添加0
      var f = parseFloat(x);
      if (isNaN(f)) {
        return false;
      }
      // eslint-disable-next-line no-redeclare
      var f = parseInt(x / 1000) / 10;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 1) {
        s += "0";
      }
      return s + "万";
    },

    /**
     * 下载数，点赞数调整
     */
    setNumberfun(data) {
      if (data >= 0 && data <= 9999) {
        return data;
      } else if (data >= 10000 && data <= 99999) {
        return this.toDecimal(data);
      } else if (data > 99999) {
        return "10万+";
      }
    },
    /**
     * 选中文件
     * @param {} file 选中的文件
     */
    async onClick(file) {
      // debugger;
      this.activestate = 2;
      if (file === this.currentFile) return false;
      if (this.isEdit) {
        // const response = await this.wpsConfig.save();
        this.isEdit = false;
        // this.$emit("refresh", true);
      }
      file.isPrintDownLoad = this.fileObject.isPrintDownLoad;
      this.currentFile = file;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          resourceId: file.resourceId,
        },
      });
      this.introjs &&
        this.$nextTick(() => {
          this.introjs.refresh();
        });
    },
    /**
     * 文件上传
     */
    uploadFiles(event) {
      console.log("event=====", event);
      if (!event.target.files || !event.target.files.length)
        return this.$message.warning(this.$t("el.classroomWps.selectFile"));
      var file = event.target.files[0];
      this.resetFiles(); // 重置表单

      // var reg = new RegExp(`(?<=\.)(${this.formatLimit.join('|')})$`);
      var sizeError = 0;
      var formatError = 0;
      var nullFile = 0;
      var suffix = this.$utils.getExtension(file.name);
      if (file.size > this.maxSize) {
        sizeError++;
      }
      if (
        this.formatLimit.length !== 0 &&
        this.formatLimit.indexOf(suffix) === -1
      ) {
        formatError++;
      }
      if (file.size <= 0) {
        nullFile++;
      }
      // 错误提示
      if (sizeError || formatError) {
        this.$message.warning(
          `${sizeError ? "文件最大" + this.maxSize / 1024 / 1024 + "M，" : ""}${
            formatError
              ? "仅允许上传" + this.formatLimit.join("、") + "格式，"
              : ""
          }请重新选择`
        );
        return;
      }
      // 前端判断文件格式
      if (
        suffix.indexOf(this.file.format) === -1 &&
        this.file.format.indexOf(suffix) === -1
      ) {
        this.$message.warning(this.$t("el.classroomWps.sametype"));
        return;
      }
      // 不允许上传空文件
      if (nullFile) {
        this.$message.warning(this.$t("el.classroomWps.filenoContent"));
        return;
      }
      console.log(file);

      checkResource({
        fileName: file.name,
        resourceSize: file.size,
        format: file.name.replace(/.+\./, ""),
        category: 1,
        knowledgeId: this.fileUploadObj.knowledgeId,
        resourceType: this.fileUploadObj.resourceType,
        originTenantId: this.$route.query.originTenantId,
      }).then((res) => {
        if (res.data == true) {
          // 重新上传接口
          let params = {
            fileName: file.name,
            xObsAcl: "private",
            source: "front",
          };
          this.obsUpload(2, file, params);
          // getOssPolicy(params)
          //   .then((ele) => {
          //     if (ele.success) {
          //       this.uploadParams = ele.data;
          //       this.ossUpload(file, 2);
          //     } else {
          //       this.$message.error(ele.message);
          //     }
          //   })
          //   .catch(() => {
          //     this.$message.error(this.$t("el.classroomWps.uploadFail"));
          //   });
        }
      });
    },
    /**
     * 停止上传
     */
    stopUpload() {
      if (this.cancel) {
        typeof this.cancel === "function" &&
          this.cancel({
            cancel: "cancel",
            response: {
              status: -1,
              message: this.$t("el.classroomWps.cancelUpload"),
            },
          });
        this.cancel = null;
      }
      this.fileLoading = false; // 页面刷新
    },
    /**
     * 删除文件
     * @param {*} file
     */
    handleDelete(file) {
      this.$confirm("", "删除确认", {
        center: true,
        customClass: "el-message-box--confirm el-message-box--danger",
        message: this.$createElement(
          "div",
          {
            class: "el-message-box--delete",
          },
          this.$t("el.classroomWps.cancelPersonal")
        ),
      }).then(() => {
        this.isEdit = false;
        delPersonResource({
          resourceId: file.resourceId,
        }).then(() => {
          this.$message.success(this.$t("el.classroomWps.deleteSuccess"));
          // 优化：删除选中资源，自动选择第一个资源。
          this.$router.replace({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              resourceId: undefined,
            },
          });
          this.$emit("refresh");
        });
      });
    },
    /**
     * 打开协议
     */
    openAgreement() {
      windowOpen(
        "http://bsk.hailiangedu.com:9192/common-resource/pdf/%E5%A4%87%E6%8E%88%E8%AF%BE%E5%B9%B3%E5%8F%B0%E6%95%B0%E5%AD%97%E4%BD%9C%E5%93%81%E5%90%88%E4%BD%9C%E5%8D%8F%E8%AE%AE.pdf"
      );
    },
    /**
     * 全屏
     */
    toggle() {
      this.$refs.filePreview.toggle();
    },
    /**
     * 配套教案路由跳转
     */
    jumpJa(file, type) {
      this.activestate = 1;
      file.isPrintDownLoad = this.fileObject.isPrintDownLoad;
      this.currentFile = file;
      // console.log(this.$route.query.id," this.pageId",resourceId)
      if (type === "JA") {
        this.$router.replace({
          path: "/classroomWps/bk-ja",
          query: {
            id: this.$route.query.id,
            resourceId: file.relatedId,
            courseId: this.$route.query.courseId,
            originTenantId: this.$route.query.originTenantId,
            originTenantType: this.$route.query.originTenantType,
          },
        });
        // this.$emit("refresh"); //页面刷新
        // this.currentFile
      }
      if (type === "KJ") {
        this.$router.replace({
          path: "/classroomWps/bk-kj",
          query: {
            id: this.$route.query.id,
            resourceId: file.relatedId,
            courseId: this.$route.query.courseId,
            originTenantId: this.$route.query.originTenantId,
            originTenantType: this.$route.query.originTenantType,
          },
        });
        // this.$emit("refresh"); //页面刷新
      }
    },
    /**
     * 获取审核意见信息
     */
    lookSuggest(packageId) {
      this.suggestdialogVisible = true;
      let params = {
        packageId: packageId,
        operateType: [2],
        category: 0,
        offset: 0,
        limit: 100,
      };
      checkSuggest(params).then((res) => {
        this.suggestInfo = res.data.records;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.checkradio {
  background: red;
  margin-top: 4px;
}
.data-box {
  max-height: 600px;
  overflow: auto;
}
.data-dialog-box {
  // padding: 10px;
  .data-dialog-titleinfo {
    text-align: left;
    padding: 0 20px 0px 0;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
    line-height: 21px;
  }
}
.data-dialog {
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  padding: 6px 12px;
}
.data-checkoutimg {
  width: 16px;
  height: 16px;
}
.data-dialog-title {
  max-width: calc(100% - 28px);
  margin-right: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 20px;
  font-size: 14px !important;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.data-dialog:hover {
  // background: rgba(73, 124, 240, 0.08);
  color: @themeBlue;
  background: url("../../../assets/images/icon/icon_checkbox_normal@2x.png")
    rgba(73, 124, 240, 0.08) no-repeat calc(100% - 12px) 6px;
  background-size: 16px 16px;
}
.skdatadialog:hover {
  color: @themeBlue;
  background: url("../../../assets/images/icon/icon_checkbox_normal@2x.png")
    rgba(rgba(148, 0, 255, 0.04)) no-repeat calc(100% - 12px) 6px;
  background-size: 16px 16px;
}
.nodata-dialog {
  padding: 10px;
  text-align: left;
  &-head {
    i {
      color: #e6a23c;
      margin-right: 10px;
    }
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 18px;
  }
  &-content {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    line-height: 24px;
    padding-left: 24px;
    margin-top: 8px;
  }
}
.suggesttitle {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 22px;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;
}
.sksuggest {
  & .el-button {
    background: @themeBlue;
  }
}
.suggestdia {
  padding: 6px;
  .suggest {
    margin-top: 10px;
    &-head {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      line-height: 17px;
      margin-bottom: 7px;
      display: flex;
      .suggest-head-people {
        padding-right: 10px;
        text-align: left;
      }
      .suggest-head-time {
        text-align: left;
      }
    }
    &-content {
      border-radius: 6px;
      background: #f7f7f7;
      padding: 8px;
      text-align: left;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      line-height: 22px;
    }
  }
}
.noSuggest {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-center {
    height: 40px;
    width: 150px;
    display: flex;
  }
  .noSuggest-img {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-info {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 40px;
    color: #282828;
    margin-left: 8px;
  }
}
.bottoninfo {
  border-top: 1px solid #f0f0f0;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
  line-height: 18px;
  padding-top: 8px;
  display: flex;
  margin-top: 8px;
}
.bottonInfoLine {
  padding-left: 4px;
  width: 200px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bottonInfoLine:hover {
  text-decoration: underline;
}
.file-list__item {
  position: relative;
  .othertip {
    width: 46px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    background: @themeBlue;
    text-align: center;
    color: #ffffff;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .othertips {
    width: 46px;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    background: @themeBlue;
    text-align: center;
    color: #ffffff;
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
.file-list__wrap {
  height: 100%;
  position: relative;
}
.file-list.file-list-sk {
  .like-active-text {
    color: @themeBlue;
  }
  .footer__inner {
    color: @themeBlue;
  }
  .footer__inner-icon {
    .gen-icon("icon_list_upload@2x") !important;
  }
}
// 暂无数据 start
.no-data_box {
  width: 100%;
  height: 100%;
  background: #f6f8fc;
  &.no-data_sk {
    .no-data_btn {
      background: @themeBlue;
    }
  }
  .no-data_img {
    width: 325px;
  }
  .no-data_text {
    font-size: 18px;
    font-weight: 600;
    color: rgba(100, 108, 149, 0.25);
    line-height: 25px;
    letter-spacing: 1px;
    margin-top: 28px;
    margin-bottom: 44px;
  }
  .no-data_icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    line-height: 38px;
    vertical-align: middle;
    cursor: pointer;
    .gen-icon("icon_list_upload2@2x");
  }
  .no-data_btn {
    width: 296px;
    height: 38px;
    background: @themeBlue;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    label {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 38px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
// 暂无数据 end
.loading-test {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  // text-align: center;
  // font-size: 20px;
  // line-height: 100vh;
  // color: #4072ee;
}
.file-list {
  position: relative;
  font-size: 14px;
  color: #444444;
  height: 100%;
  // width: 274px;
  width: calc(40% - 60px);
  min-width: 300px;
  border-right: 1px solid #ebedf5;
  background-color: #f6f6f6;
  z-index: 0;
  .el-scrollbar_x_content {
    padding: 15px 20px 70px;
  }
  &__gooddown {
    padding: 4px 0 4px 0;
    display: flex;
    flex-direction: row-reverse;
  }

  &__good,
  &__down {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6f8aa3;
    line-height: 18px;
    min-width: 10px;
    height: 18px;
    cursor: pointer;
    text-indent: 2px;
    // margin-right: 6px;
    // padding-left: 8px;
    display: flex;
  }
  &__down {
    padding-left: 20px;
    background: url("../../../assets/images/icon/icon_list_download_normal.svg")
      no-repeat 0 1px;
    background-size: 16px 16px;
  }
  &__downno {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6f8aa3;
    line-height: 18px;
    min-width: 10px;
    height: 18px;
    text-indent: 2px;
    display: flex;
    padding-left: 20px;
    background: url("../../../assets/images/icon/icon_list_download_normal.svg")
      no-repeat 0 1px;
    background-size: 16px 16px;
  }
  .skdown {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6f8aa3;
    line-height: 18px;
    min-width: 10px;
    height: 18px;
    cursor: pointer;
    text-indent: 2px;
    display: flex;
    padding-left: 20px;
    background: url("../../../assets/images/icon/icon_list_download_normal.svg")
      no-repeat 0 1px;
    background-size: 16px 16px;
  }
  .skdown:hover {
    background: url("../../../assets/images/icon/icon_list_download_selected.svg")
      no-repeat 0 1px;
    background-size: 16px 16px;
    color: @themeBlue;
  }
  &__good {
    margin-right: 12px;
  }
  .like-active-iconed {
    // display: none;
    width: 16px;
    height: 16px;
  }
  .like-active-icon {
    width: 16px;
    height: 16px;
  }
  &__good:hover {
    color: @themeBlue;
  }
  &__good-act {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 18px;
    min-width: 20px;
    height: 18px;
    cursor: pointer;
    margin-right: 12px;
    float: right;
    text-indent: 2px;
    color: @themeBlue;
    display: flex;
  }
  .skstyle {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6f8aa3;
    line-height: 18px;
    min-width: 10px;
    height: 18px;
    cursor: pointer;
    text-indent: 2px;
    margin-right: 12px;
    display: flex;
  }
  .skstyle:hover {
    color: @themeBlue;
  }
  .skstyleed {
    color: @themeBlue;
  }
  &__down:hover {
    background: url("../../../assets/images/icon/icon_list_download_selected.svg")
      no-repeat 0 1px;
    background-size: 16px 16px;
    color: @themeBlue;
  }
  .firstFile {
    position: relative;
  }
  &__boutique {
    color: white;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__item {
    width: 100%;
    margin-bottom: 15px;
    padding: 20px 12px 11px 12px;
    transform: translateY(0) scale(1);
    background-color: #fff;
    // transition: transform 0.5s linear 0s;
    // transform-origin: 80% top;
    cursor: pointer;
    box-sizing: border-box;
    &.active {
      background-color: #fff;
      padding-left: 9px;
      border-left: 3px solid @themeBlue;
      &.bk {
        border-left: 3px solid @themeBlue;
        padding-left: 9px;
        border-radius: 1.5px 0 0 1.5px;
      }
    }
    // &.last {
    //   margin-bottom: 70px;
    // }
  }
  &__name {
    color: #000;
    max-height: 40px;
    line-height: 20px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    min-height: 1em;
    cursor: default;
  }
  &__infomation {
    color: #818181;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 26px;
    display: flex;
    padding-bottom: 10px;
  }
  &__schoolName,
  &__authName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__schoolName {
    max-width: 130px;
    padding-right: 2px;
  }
  &__authName {
    max-width: 82px;
    padding-left: 2px;
  }
  &__handle {
    padding-top: 11px;
    margin-bottom: 6px;
    min-height: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    & .el-button {
      color: @themeBlue;
      height: 100%;
      font-size: 10px;
      background: none;
      line-height: 100%;
      text-align: center;
      cursor: pointer;
      display: inline-block;
      padding: 0;
      border: 0;
      border-radius: 2px;
    }
  }
  .file-list__dosomething {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  &__operate {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .file-list__type {
      img {
        width: 40px;
      }
    }
    .file-list__descript {
      width: calc(100% - 48px);
      .file-list__like {
        margin-top: 6px;
      }
    }
  }
  &__like {
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-top: 0;
    .like-icon {
      width: 12px;
      height: 12px;
      margin-right: 3px;
      .gen-icon("icon_list_fabulous2");
    }
    .like-active-icon {
      width: 12px;
      height: 12px;
      margin-right: 3px;
      .gen-icon("icon_list_fabulous");
    }
    .move {
      animation: move 1s 0s infinite;
      -webkit-animation: move 1s 0s infinite;
      animation-iteration-count: 1;
    }
    span {
      line-height: 12px;
      vertical-align: middle;
    }
    .author-icon {
      width: 12px;
      height: 12px;
      margin-right: 3px;
      .gen-icon("icon_list_name");
    }
    .like-text {
      // font-family: SFUIText-Medium, SFUIText;
      color: #6f8aa3;
    }
    &.like-active {
      .like-icon {
        .gen-icon("icon_list_fabulous");
      }
      .like-text {
        color: #f57022;
      }
    }
  }
  &__share {
    width: 15px;
    height: 20px;
    vertical-align: top;
    margin-left: 6px;
    cursor: pointer;
    .gen-icon("share");
    &.bk-active:hover {
      .gen-icon("share_bk_active");
    }
    &.sk-active:hover {
      .gen-icon("share_bk_active");
    }
  }
  &__change {
    // display: flex;
    // flex-direction: row-reverse;
    padding: 0px 0 4px 0;
    min-height: 20px;
    line-height: 20px;
    justify-content: space-between;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    .file-list__like {
      background: #faf9ff;
      margin-right: 10px;
      border: 1px solid rgba(96, 73, 255, 0.14);
      height: 24px;
      border-radius: 12px;
      padding: 0 10px;
      font-size: 12px;
      color: @themeBlue;
      &:hover {
        background: #f0eeff;
      }
    }
  }
  .center_change {
    min-height: 0;
    line-height: 0;
    padding-top: 0;
    margin-bottom: 0;
    // margin-top: 10px;
    .file-list__like {
      position: absolute;
      left: 15px;
      bottom: 10px;
    }
  }
  &__author {
    font-size: 12px;
    // font-family: MicrosoftYaHei;
    color: #949494;
  }
  &__edit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6f8aa3;
    line-height: 18px;
    width: 40px;
    height: 18px;
    cursor: pointer;
    margin-right: 6px;
    text-align: right;
    float: right;
    background: url("../../../assets/images/icon/icon_list_modify_normal@2x.png")
      no-repeat 0 3px;
    background-size: 12px 12px;
  }
  &__edit:hover {
    background: url("../../../assets/images/icon/icon_list_modify_selected@2x.png")
      no-repeat 0 3px;
    background-size: 12px 12px;
    color: @themeBlue;
  }
  .skedit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6f8aa3;
    line-height: 18px;
    width: 40px;
    height: 18px;
    cursor: pointer;
    margin-right: 6px;
    text-align: right;
    float: right;
    background: url("../../../assets/images/icon/icon_list_modify_normal@2x.png")
      no-repeat 0 3px;
    background-size: 12px 12px;
  }
  .skedit:hover {
    background: url("../../../assets/images/icon/icon_list_modify_selected@2x.png")
      no-repeat 0 3px;
    background-size: 12px 12px;
    color: @themeBlue;
  }
  &__suggest {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6f8aa3;
    line-height: 18px;
    width: 62px;
    height: 18px;
    cursor: pointer;
    margin-right: 6px;
    text-align: right;
    float: right;
    background: url("../../../assets/images/icon/icon_list_opinion_normal@2x.png")
      no-repeat -1px 3px;
    background-size: 12px 12px;
  }
  &__suggest:hover {
    background: url("../../../assets/images/icon/icon_list_opinion_selected@2x.png")
      no-repeat -1px 3px;
    background-size: 12px 12px;
    color: @themeBlue;
  }
  .sksuggest {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6f8aa3;
    line-height: 18px;
    width: 62px;
    height: 18px;
    cursor: pointer;
    margin-right: 6px;
    text-align: right;
    float: right;
    background: url("../../../assets/images/icon/icon_list_opinion_normal@2x.png")
      no-repeat -1px 3px;
    background-size: 12px 12px;
  }
  .sksuggest:hover {
    background: url("../../../assets/images/icon/icon_list_opinion_selected@2x.png")
      no-repeat -1px 3px;
    background-size: 12px 12px;
    color: @themeBlue;
  }
  &__tip {
    font-size: 13px;
  }
  &.hide {
    width: 0px;
  }
  .sk-class {
    color: @themeBlue;
    // padding: 4px 8px;
    // width: 60px;
    height: 20px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // line-height: 20;
    font-size: 10px;
    // background: #fff;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    padding: 0 7px;
    border-radius: 10px;
    border: 1px solid rgba(96, 73, 255, 0.5);
    background: rgba(148, 0, 255, 0.04);
    & .el-button {
      color: @themeBlue;
    }
  }
  .sk-bg-hover {
    background: #705cff !important;
    color: rgba(255, 255, 255, 1) !important;
    & .el-button {
      color: rgba(255, 255, 255, 1) !important;
    }
  }
  .bk-bg-hover {
    background: #705cff !important;
    color: rgba(255, 255, 255, 1) !important;
    & .el-button {
      color: rgba(255, 255, 255, 1) !important;
    }
  }
  .bk-class {
    // width: 60px;
    height: 20px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // line-height: 20;
    font-size: 12px;
    // padding: 4px 8px;
    // background: #faf9ff;
    color: @themeBlue;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    padding: 0 7px;
    border-radius: 10px;
    border: 1px solid rgba(96, 73, 255, 0.5);
    background: rgba(73, 124, 240, 0.08);
    & .el-button {
      color: @themeBlue;
    }
  }
  .already-like {
    // background: #705CFF;
    // border: 1px solid #705CFF;
  }
  &__delete {
    // float: right;
    color: #919191;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    font-size: 0;
    &::before {
      content: "";
      vertical-align: middle;
      display: inline-block;
      width: 12px;
      height: 14px;
      margin-right: 3px;
      .gen-icon(delete);
    }
    &:active {
      color: #f5222d;
      &::before {
        .gen-icon(delete_active);
      }
    }
    span {
      vertical-align: middle;
      display: inline-block;
      font-size: 12px;
      transform: scale(0.9166);
      line-height: 14px;
    }
  }
  &__rename {
    span {
      font-size: 10px;
      color: rgba(73, 124, 240, 1);
      line-height: 20px;
      background: rgba(249, 246, 255, 1);
      border-radius: 2px;
      cursor: pointer;
    }
    margin-right: 16px;
  }
  &__btns {
    float: right;
    white-space: nowrap;
  }
  &__change-text {
    font-size: 10px;
    color: rgba(73, 124, 240, 1);
    line-height: 20px;
    background: rgba(249, 246, 255, 1);
    border-radius: 2px;
  }
  &__toggle {
    font-size: 12px;
    position: absolute;
    width: 22px;
    height: 64px;
    background: rgba(153, 188, 220, 0.6);
    right: 0px;
    bottom: 0;
    color: #fff;
    line-height: 14px;
    border-radius: 12px 0px 0px 0px;
    cursor: pointer;
    text-align: center;
    padding-top: 2px;
    user-select: none;
    z-index: 100;
  }
  .footer {
    line-height: 16px;
    padding-bottom: 16px;
    text-align: center;
    margin-top: 15px;
    &__inner {
      width: 100%;
      padding: 11px 0;
      background: #ffffff;
      border-radius: 28px;
      color: @themeBlue;
      font-size: 14px;
      display: inline-block;
      cursor: pointer;
      border: 1px solid rgba(96, 73, 255, 0.14);
      .footer__inner-icon {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        .gen-icon("icon_list_upload@2x");
      }
      &:hover {
        border: 1px solid rgba(96, 73, 255, 0.14);
        background: #f6f5ff;
      }
    }
    label {
      display: inline-block;
      font-size: 14px;
      margin-left: 6px;
      vertical-align: middle;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
  .el-scrollbar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .el-tag {
    padding: 0 1px;
    background: rgba(111, 138, 163, 0.8);
    border-radius: 1px;
    font-size: 10px;
    // font-family: MicrosoftYaHei;
    color: #ffffff;
    border: 0;
    height: 18px;
    line-height: 18px;
  }
  // .el-tag-bk {
  //   cursor: pointer;
  //   color: rgba(73, 124, 240, 1) !important;
  //   border-color: rgba(73, 124, 240, 1) !important;
  // }
  // .el-tag-sk {
  //   cursor: pointer;
  //   color: rgba(151, 86, 255, 1) !important;
  //   border-color: rgba(151, 86, 255, 1) !important;
  // }
  .el-tag .text {
    font-size: 12px;
    transform: scale(0.8333);
  }
}
.bk__center {
  &__title {
    font-size: 14px;
    padding: 0px 2px 16px 0;
  }
  &__titlefirst {
    padding-top: 0;
  }
  &__icon {
    width: 16px;
    height: 16px;
    .gen-icon("details");
    vertical-align: middle;
    margin-left: 10px;
    // &:hover {
    //   .gen-icon("details_hover");
    // }
    &.course {
      width: 17px;
      .gen-icon("icon_couses");
      vertical-align: middle;
      margin-left: 0px;
      margin-right: 10px;
    }
  }

  &__text {
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    color: #444444;
  }

  /deep/ .el-collapse {
    border-color: 10px solid #ebeef5;
    border-color: 10px solid #ebeef5;
    padding: 10px 0;
  }
  /deep/ .el-collapse-item__content {
    padding-bottom: 0;
    .not-data {
      padding: 10px 0;
      font-size: 14px;
      font-weight: 400;
    }
  }
  /deep/ .el-collapse-item__header.is-active {
    border-bottom: 1px solid #ebeef5;
  }
  .file-list__item.last {
    margin-bottom: 0;
    border-bottom: 0;
    border-bottom: 0;
  }
  .not-data {
    color: #a4abd3;
    font-size: 14px;
    padding: 27px 100px;
    background: #fff;
    margin: 16px 0;
  }
}
.bk_center_box {
  .file-list__author {
    width: 100%;
    // height: 13px;
    margin-top: 6px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #6f8aa3;
    .file-list__like {
      margin-top: 0;
      display: inline-block;
    }
    .file-list__like:first-child {
      margin-right: 8px;
    }
  }
}
.my {
  .file-list__item.first {
    border-top: 1px solid #ebedf5;
  }
  .bk-class {
    // background: #fff;
    border: 1px solid rgba(96, 73, 255, 0.14);
  }
  .file-list__like {
    color: #6f8aa3;
  }
}
.icon-details {
  width: 16px;
  height: 16px;
  vertical-align: top;
  cursor: pointer;
  .gen-icon("details");
  &:hover {
    .gen-icon("details_hover");
  }
}
.icon-toggle {
  width: 16px;
  height: 16px;
}
.not-data {
  text-align: center;
  color: rgba(100, 108, 149, 0.47);
  padding-top: 260px;
  line-height: 28px;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 600;
}
.file-preview {
  position: absolute;
  left: calc(40% - 60px);
  right: 0;
  top: 0;
  bottom: 0;
}
.el-dialog--loading {
  /deep/ .el-dialog__header {
    padding-top: 20px !important;
  }
  .el-dialog__content {
    img {
      margin-top: 20px;
      margin-bottom: 28px;
    }
    div {
      font-size: 19px;
      color: rgba(100, 108, 149, 1);
      line-height: 37px;
      letter-spacing: 1px;
      // margin-bottom: 40px;
    }
  }
}
.el-message-box--delete {
  width: 285px;
  font-size: 19px;
  color: rgba(100, 108, 149, 1);
  line-height: 37px;
  letter-spacing: 1px;
  margin: 20px auto 38px;
}
.change {
  .change-container {
    height: 600px;
  }
  .change-content {
    font-size: 18px;
    color: rgba(100, 108, 149, 1);
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 23px;
  }
  /deep/ .el-dialog__body {
    padding: 0px 40px 32px 40px;
  }
  .change-item {
    padding: 0 36px 0 24px;
    margin-bottom: 24px;
    .item-title {
      margin-bottom: 10px;
      font-size: 15px;
      color: rgba(68, 68, 68, 1);
      line-height: 26px;
      letter-spacing: 1px;
    }
    .img {
      width: 100%;
    }
  }
}
.file-preview.extension {
  left: 0 !important;
}
.dropdown-action {
  float: right;
  & > img {
    width: 12px;
    height: 12px;
    margin-top: 3px;
    cursor: pointer;
  }
  &-more {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6f8aa3;
    line-height: 18px;
    height: 18px;
  }
}
.dropdown-action-flex {
  display: flex;
}
.dropdown-menu {
  // max-width: 200px;
  &-item {
    color: rgba(96, 98, 102, 1);
    line-height: 40px;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 400;
    cursor: pointer;
    & + & {
      border-top: 1px solid rgba(235, 235, 235, 1);
    }
    &:hover {
      background: rgba(245, 247, 250, 1);
    }
    &.cmm {
      &.bk {
        &:hover {
          color: @themeBlue;
        }
      }
      &.sk {
        &:hover {
          color: @themeBlue;
        }
      }
    }
    &.sc {
      &:hover {
        color: #f5222d;
      }
    }
    &.xg {
      padding-left: 7px;
      padding-right: 7px;
      text-align: center;
    }
  }
}
.rename {
  /deep/ .el-dialog__header {
    padding-top: 20px !important;
  }
}
.dialog__box {
  display: flex;
  label {
    padding: 6px 0;
    // font-family: MicrosoftYaHei;
  }
  span {
    color: #f5222d;
  }
  /deep/ textarea {
    height: 120px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid @themeBlue;
    resize: none;
    box-sizing: border-box;
  }
  label + div {
    flex: 1;
  }
  &__tip {
    color: #f56c6c;
    font-size: 12px;
    min-height: 18px;
    margin: 5px 0;
  }
  &__link {
    // font-family: MicrosoftYaHei;
    color: #646c95;
    text-decoration: underline;
    margin-top: 25px;
    cursor: pointer;
    display: inline-block;
  }
}
.dialog__footer .el-button + .el-button {
  margin-left: 36px;
}
.introjs-title {
  font-size: 28px;
  line-height: 40px;
  position: fixed;
  top: 117px;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100000000;
  color: #fff;
}
.introjs-btn-group {
  position: fixed;
  z-index: 100000000;
  bottom: 68px;
  left: 0;
  right: 0;
  text-align: center;
  // font-family: MicrosoftYaHei;
}
.introjs-btn {
  font-size: 19px;
  letter-spacing: 1px;
  color: #282828;
  line-height: 23px;
  padding: 13px 31px 14px 32px;
  border-radius: 50px;
}
.introjs-tooltipReferenceLayer-clone {
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 99999999;
  background-color: transparent;
  transition: all 0.3s ease-out;
}
.introjs-tooltip-clone {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  transition: opacity 0.1s ease-out;
}
.not-operate {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999999;
}
/deep/ .el-dialog--center .el-dialog__body {
  padding: 22px 73px 30px 34px;
}
.skbuttonbg {
  width: 50%;
  padding-right: 10px;
  & .el-button {
    background: @themeBlue;
    border: 0;
    float: right;
  }
}
.bkbuttonbg {
  width: 50%;
  padding-right: 10px;
  & .el-button {
    background: @themeBlue;
    float: right;
  }
}
.noskbuttonbg {
  width: 50%;
  padding-left: 10px;
  & .el-button {
    float: left;
  }
  & .el-button:hover {
    background: @themeBlue;
    color: #ffffff;
  }
}
.nobkbuttonbg {
  width: 50%;
  padding-left: 10px;
  & .el-button {
    float: left;
  }
  & .el-button:hover {
    background: @themeBlue;
    color: #ffffff;
  }
}
.skdialog-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.copywriter {
  text-align: left;
  line-height: 17px;
  margin: 6px 0 16px 0;
  // padding-left: 10px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6f8aa3;
  display: flex;
  align-items: center;
  img {
    width: 12px;
    height: 12px;
  }
}
.skbuttonbgs {
  width: 75px;
  padding-left: 10px;
  & .el-button {
    background: @themeBlue;
    border: 0;
    // float: right;
  }
}
.bkbuttonbgs {
  width: 75px;
  padding-left: 10px;
  & .el-button {
    background: @themeBlue;
    // float: right;
  }
}
.noskbuttonbgs {
  // width: calc(100% - 125px);
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 6px;
  & .el-button {
    float: left;
  }
  & .el-button:hover {
    background: @themeBlue;
    color: #ffffff;
  }
}
.nobkbuttonbgs {
  // width: calc(100% - 125px);
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 6px;
  & .el-button {
    float: left;
  }
  & .el-button:hover {
    background: @themeBlue;
    color: #ffffff;
  }
}
.groupResource {
  max-height: 540px;
  overflow: hidden;
}
.groupResourcesch {
  max-height: 540px;
  overflow: hidden;
  margin-top: 16px;
}
.groupResourcehidden {
  //点击收起后，全部隐藏
  max-height: 0px;
  overflow: hidden;
}
.groupResourceUnfoldsch {
  margin: 16px 0;
}
.groupResourcehiddensch {
  //点击收起后，全部隐藏
  max-height: 0px;
  overflow: hidden;
  margin: 0;
}
.groupResourceUnfold {
  margin-bottom: 1px;
}

.bk-headtitle {
  display: flex;
  justify-content: space-between;
  .bk-headtitle__text {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
  }
  .bk-headtitle-onOrOff {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6f8aa3;
    cursor: pointer;
    line-height: 18px;
    width: 48px;
    height: 18px;
    border-radius: 9px;
    background: rgba(111, 138, 163, 0.05);
    text-align: center;
  }
}
.checkoutall {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px 0 20px 0;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5951f6;
  cursor: pointer;
  img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
}
.personclass {
  padding-top: 0px;
}
.schooltitle {
  padding: 0px 2px 0 0;
}
.bkspktsl {
  border: none;
}
.schoolbox {
  margin-bottom: 16px;
}
.jumpclass {
  border: none;
}
// .el-progress--circle {
//   position: fixed;
//   top: 40%;
//   z-index: 999;
//   background: #fff;
//   padding: 15px;
//   left: 40%;
// }
</style>

<style lang="less">
#classboxmao {
  z-index: 10000;
  width: 100px;
  height: 100px;
  background: #111;
  display: block;
}
.popover-dropdown {
  min-width: auto;
  width: 90px;
  padding: 5.5px 0 5.5px 0;
  margin-left: -10px;
}
/* 引导样式修改 */
@bg-color-blue: @themeBlue;
@bg-color-red: #ef5e7a;
.introjs-step-blue {
  background: @bg-color-blue;
  color: #fff;
  .introjs-arrow {
    border-right-color: @bg-color-blue;
  }
}
.introjs-step-red {
  background: @bg-color-red;
  color: #fff;
  .introjs-arrow {
    border-right-color: @bg-color-red;
  }
}
.introjs-prepare {
  font-size: 19px;
  letter-spacing: 1px;
  line-height: 23px;
  padding: 15px 18px 14px 16px;
  // font-family: MicrosoftYaHei;
  .introjs-arrow {
    border-width: 7px;
    left: -14px;
    top: 16px;
  }
}
.introjs-step-1:extend(.introjs-step-blue all) {
  min-width: auto;
  width: 169px;
  margin-left: 10px;
}
.introjs-step-2:extend(.introjs-step-red all) {
  min-width: auto;
  width: 169px;
  margin-left: 15px;
  margin-top: 290px;
}
.introjs-step-3:extend(.introjs-step-red all) {
  margin-top: 205px;
  margin-left: -145px;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #ef5e7a;
    border-radius: 50%;
    box-shadow: 0 0 2px 2px rgba(239, 94, 122, 0.16);
    position: absolute;
    top: 20px;
    left: -28px;
  }
}
.introjs-step-4:extend(.introjs-step-red all) {
  margin-left: 10px;
  width: 226px;
}
.introjs-step-5:extend(.introjs-step-blue all) {
  margin-top: 96px;
  margin-left: -18px;
}
.introjs-step-6:extend(.introjs-step-red all) {
  width: 240px;
  margin-top: 173px;
  margin-left: -135px;
}
.el-popover-zIndex-hot,
.el-messsage-custom {
  z-index: 9999999 !important;
}
.introjs-dialog {
  width: 400px;
  height: 230px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -115px;
  pointer-events: none;
}
.move {
  animation: move 1s 0s infinite;
  -webkit-animation: move 1s 0s infinite;
  animation-iteration-count: 1;
}
@keyframes move {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transform: scale(1);
  }
  25% {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  50% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
    transform: scale(1.5);
  }
  75% {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transform: scale(1);
  }
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transform: scale(1);
  }
  25% {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  50% {
    -webkit-transform: rotate(-25deg);
    transform: rotate(-25deg);
    transform: scale(1.5);
  }
  75% {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transform: scale(1);
  }
}
.en-status {
  .file-list__edit {
    width: 50px;
  }
  .file-list {
    .skedit {
      width: 50px;
    }
  }
  .bottoninfo {
    flex-wrap: wrap;
  }
  .bottonInfoLine {
    width: 100%;
    padding-left: 0;
  }
}
</style>
