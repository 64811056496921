<template>
  <div v-loading="loading" class="file-preview">
    <div v-if="notData" class="not-data">{{ $t("el.common.noData") }}</div>
    <template v-if="!isCanPreview">
      <div
        v-if="
          ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'].indexOf(
            fileExtension
          ) !== -1
        "
        class="no-data_box img-box"
      >
        <img :src="imgUrl" alt="" />
      </div>
      <div class="no-data_box flex flex-column flex-m flex-c" v-else>
        <img
          src="@/assets/images/pic_empty_course@2x.png"
          class="no-data_img"
        />
        <div class="no-data_text">{{ $t("el.classroomWps.canotPreview") }}</div>
      </div>
    </template>
    <template v-if="isAuth && isCanPreview">
      <fullscreen
        ref="fullscreen"
        style="width: 100%; height: 100%"
        @change="onFullscreenChange"
      >
        <div v-if="file.isPrintDownLoad && !isCompare" class="printButton">
          <el-button
            @click="printClick"
            size="mini"
            type="primary"
            v-if="
              colorType[0] + colorType[1] == 'bkja' ||
              colorType[0] + colorType[1] == 'skzy' ||
              colorType[0] + colorType[1] == 'skkqyx'
            "
          >
            <i class="el-icon-printer"></i>
            {{ $t("el.classroomWps.print") }}</el-button
          >
        </div>
        <!-- <div
          :class="{
            setfloorbutton: colorType[0] == 'bk',

            sksetfloorbutton: colorType[0] == 'sk',

            fullfloorset: fullscreen == true,
          }"
          v-if="initbutton"
        >
          <div v-if="floorstate">
            <el-button @click="setfloorbutton" size="mini" type="primary">{{
              $t("el.common.pageUp")
            }}</el-button>
          </div>
          <div v-if="!floorstate">
            <el-button size="mini" type="primary" disabled>{{
              $t("el.common.pageUp")
            }}</el-button>
          </div>
        </div>
        <div
          :class="{
            setbutton: colorType[0] == 'bk',

            sksetbutton: colorType[0] == 'sk',

            fullset: fullscreen == true,
          }"
          v-if="initbutton"
        >
          <div v-if="nextstate">
            <el-button @click="setbutton" size="mini" type="primary">{{
              $t("el.common.pageDown")
            }}</el-button>
          </div>
          <div v-if="!nextstate">
            <el-button size="mini" type="primary" disabled>{{
              $t("el.common.pageDown")
            }}</el-button>
          </div>
        </div> -->
        <div
          v-if="
            ['doc', 'docx', 'pdf', 'PDF', 'xls', 'xlsx'].indexOf(
              fileExtension
            ) !== -1
          "
          class="doc"
        >
          <div id="wpsBox" v-if="pptreadoredit">
            <!-- 预览状态 -->
            <wps-file
              v-if="wpsSdkVersion === 1"
              ref="pptwps"
              v-bind:wpsUrl="wpsReadUrl"
              v-bind:token="wpsReadtoken"
              v-bind:type="1"
              :courseware="courseware"
            />
            <!-- wps v2 版本 预览状态 -->
            <WpsFileV2
              v-if="wpsSdkVersion === 2"
              ref="pptwps"
              v-bind:token="wpsReadtoken"
              v-bind:type="1"
              v-bind:wpsParams="wpsReadParams"
              :courseware="courseware"
            />
            <!-- 编辑状态 -->
          </div>
          <div id="editwpsBox" v-if="!pptreadoredit">
            <EditWpsFile
              v-if="wpsSdkVersion === 1"
              ref="pptwps"
              v-bind:wpsUrl="wpsEditUrl"
              v-bind:token="wpstoken"
              v-bind:type="1"
              :courseware="courseware"
            />
            <EditwpsFileV2
              v-if="wpsSdkVersion === 2"
              ref="pptwps"
              v-bind:wpsParams="wpsEditParams"
              v-bind:token="wpstoken"
              v-bind:type="1"
              :courseware="courseware"
            />
          </div>
          <div
            class="whiteboard-exit"
            v-if="fullscreen"
            @click.stop="exitFullScreen"
            @mousedown.stop
          >
            <img src="~@/assets/images/icon/icon_norma.png" />

            <div class="exit-info">
              {{ $t("el.classroomWps.exitFullScreen") }}
            </div>

            <!-- <img
              v-else
              src="~@/assets/images/icon/icon_full.png"
              @click.stop="toggle"
              @mousedown.stop
              /> -->
          </div>
          <!-- <wps-file v-bind:wpsUrl="this.wpsEditUrl" v-bind:token="token" /> -->
        </div>
        <div
          v-else-if="
            [
              'mp4',
              'mov',
              'mp3',
              'MP3',
              'wav',
              'WAV',
              'flac',
              'FLAC',
              'aac',
              'AAC',
              'm4a',
              'M4A',
            ].indexOf(fileExtension) !== -1
          "
          class="video"
          @contextmenu.prevent="menuPlayer()"
        >
          <div v-if="testdata" class="videotips">
            {{ testdata }}
          </div>
          <div class="prism-player" ref="plays" id="player-con"></div>

          <!-- <video-player
            ref="videoPlayer"
            :x5-playsinline="true"
            :playsinline="true"
            :webkit-playsinline="true"
            :options="{
            muted: true,
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            notSupportedMessage: '此视频暂无法播放，请稍后再试',
            sources: [
            {
            type: 'video/mp4',
            src: fileUrl,
            },
            ],
            }"
            ></video-player> -->
        </div>
        <div
          v-else-if="
            ['ppt', 'pptx', 'PPT', 'PPTX'].indexOf(fileExtension) !== -1
          "
          class="ppt mask-wps"
          ref="ppt"
        >
          <div class="pages" v-if="showPPTpages && !isEdit">
            <div
              :class="wpscurryPage !== 1 ? 'page-back' : 'page-no-back'"
              @click="setfloorbutton"
            >
              <i class="el-icon-caret-left"></i>
            </div>
            <div class="page-numbers" @click="showPagePreview">
              {{ wpscurryPage }}/{{ wpscount }}
            </div>
            <div
              :class="wpscurryPage == wpscount ? 'page-no-next' : 'page-next'"
              @click="setbutton"
            >
              <i class="el-icon-caret-right"></i>
            </div>
          </div>
          <div id="wpsBox">
            <!-- 预览状态 根据浏览器版本判断 -->
            <wps-file
              v-if="pptreadoredit && wpsSdkVersion === 1"
              ref="pptwps"
              v-bind:wpsUrl="wpsReadUrl"
              v-bind:token="wpsReadtoken"
              v-bind:type="2"
              v-bind:wpscurryPage="wpscurryPage"
              @changewpscurryPage="changewpscurryPage"
              @setinitdata="setinitdata"
              @setbuttonstate="setbuttonstate"
              :courseware="courseware"
            />
            <!-- 编辑状态 -->
            <!-- v2 版本 预览状态 -->
            <WpsFileV2
              v-if="pptreadoredit && wpsSdkVersion === 2"
              ref="pptwps"
              v-bind:wpsParams="wpsReadParams"
              v-bind:token="wpsReadtoken"
              v-bind:type="2"
              v-bind:wpscurryPage="wpscurryPage"
              @changewpscurryPage="changewpscurryPage"
              @setinitdata="setinitdata"
              @setbuttonstate="setbuttonstate"
              :courseware="courseware"
            />
          </div>
          <div id="editwpsBox" v-if="!pptreadoredit">
            <EditWpsFile
              v-if="wpsSdkVersion === 1"
              ref="pptwps"
              v-bind:wpsUrl="wpsEditUrl"
              v-bind:token="wpstoken"
              v-bind:type="2"
              :courseware="courseware"
            />
            <EditwpsFileV2
              v-if="wpsSdkVersion === 2"
              ref="pptwps"
              v-bind:wpsParams="wpsEditParams"
              v-bind:token="wpstoken"
              v-bind:type="2"
              :courseware="courseware"
            />
          </div>
          <!-- <iframe
          :src="file.resourceUrl | officePreviewUrl('ppt', isPrint, true)"
          frameborder="0"
          v-else
          ></iframe> -->
          <div
            class="whiteboard-exit"
            v-if="fullscreen"
            @click.stop="exitFullScreen"
            @mousedown.stop
          >
            <img src="~@/assets/images/icon/icon_norma.png" />

            <div class="exit-info">
              {{ $t("el.classroomWps.exitFullScreen") }}
            </div>

            <!-- <img
              v-else
              src="~@/assets/images/icon/icon_full.png"
              @click.stop="toggle"
              @mousedown.stop
              /> -->
          </div>
          <div class="hide-edit" v-if="!file.category"></div>
        </div>
      </fullscreen>
      <div
        v-if="!isEdit && !isCompare"
        class="creator-center__btn"
        :class="[
          fileExtension,
          this.$i18n.locale === 'en_US' ? 'creator-center__btn_en' : '',
          { 'is-print': isPrint },
        ]"
        @click="onShowCreator()"
      ></div>
    </template>
    <!--创作者中心富文本编辑器 -->
    <el-dialog
      width="900px"
      class="editdialog"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="editVisible"
    >
      <div slot="title" class="editdialog-header">
        <img
          src="~@/assets/images/pic_idea.png"
          class="editdialog-header-icon"
        />
        <div class="editdialog-header-title">
          {{ $t("el.classroomWps.creatorsCenter") }}
        </div>

        <div class="editdialog-header-stitle">
          *
          {{ $t("el.classroomWps.inviteMsg") }}
        </div>
      </div>
      <div class="editdialog-content">
        <editor-bar
          :isClear="isClear"
          :config="{
            uploadImgMaxSize: 5 * 1024 * 1024,
          }"
          :showImg="true"
          :baseUpload="uploadUrlParams"
          v-model="submitData.description"
        ></editor-bar>
      </div>
      <span slot="footer">
        <el-button
          type="primary"
          size="medium"
          class="editdialog-footer-btn"
          :loading="centerLoading"
          :disabled="centerLoading"
          @click="centerSubmit"
          >{{ $t("el.common.confirm") }}</el-button
        >
        <el-button
          @click="
            submitData.description = '';

            editVisible = false;
          "
          size="medium"
          class="editdialog-footer-btn"
          >{{ $t("el.common.cancel") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import fullscreen from "vue-fullscreen";
import EditorBar from "@/components/global/wangEdit1";
import WpsFile from "@/views/components/wps-file.vue";
import WpsFileV2 from "@/views/components/wps-file-v2.vue";
import EditWpsFile from "@/views/components/editwps-file.vue";
import { getToken } from "@/core/auth";
import { addCreatorCenter } from "@/api/classroom/creat-center";
import {
  checkResourceAuth,
  webofficeUrl,
  getWPSParam,
  getnewVideoUrl,
  getOssPolicyV2,
  getObsPolicy,
} from "@/api/classroom";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
Vue.use(fullscreen);
import { mapActions } from "vuex";
import EditwpsFileV2 from "../../components/editwps-file-v2.vue";

import { initBrowserPrompt } from "../../../utils/index";

import { checkBSKAuth } from "@/api/classroom/index";

export default {
  name: "editfile-preview",

  components: {
    EditorBar,
    WpsFile,
    videoPlayer,
    EditWpsFile,
    WpsFileV2,
    EditwpsFileV2,
  },

  props: {
    colorType: "",
    /**
     * 文件数据
     */
    file: {
      type: Object,
      default: null,
    },
    /**
     * 课件对象
     */
    courseware: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否无数据
     */
    notData: {
      type: Boolean,
      default: false,
    },
    /**
     * 知识点Id
     */
    id: {
      required: true,
    },
    courseId: {
      required: true,
    },
    /**
     * 需要检查资源
     */
    needCheckResource: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否是编辑wps
     */
    isEdit: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否是对照模式
     */
    isCompare: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wpsSdkVersion: 2, // 1: 初始版本 2: 改造版本
      showPPTpages: true, // 判断是否为ppt显示右下角页码
      pagePreview: true, // 当前预览PPT的显示框状态
      floorstate: false,
      nextstate: false,
      initbutton: false,
      pptreadoredit: true,
      testdata: "",
      uploadUrlParams: {}, // OSS 参数
      editVisible: false, // 创作者中心
      isClear: false,
      submitData: {
        resourceId: this.file && this.file.resourceId, // 资源id
        knowledgeId: this.$route.query.id, // 知识点id
        vedioLocation: 0, // 视频定位 单位：秒 非视频传0
        description: "", // 描述
      },
      loading: false, // 加载数据
      isAuth: false, // 文件是否有权限
      vedioLocation: this.$route.query.vedioLocation, // 视频定位位置
      fullscreen: false,
      wpsEditUrl: "", // 编辑模式下wps url
      wpstoken: "",
      wpsReadUrl: "", // 只读模式下wps url
      wpsReadParams: {}, // wps v2 版本 预览传递参数
      wpsEditParams: {}, // wps v2 版本 编辑传递参数
      wpsReadtoken: "",
      wpscount: "1", // 总页数
      wpscurryPage: 1, // 当前页码
      picstate: true,
      isWantedit: true,
      fileUrl: "",
      imgUrl: "", // 图片地址
      isFinish: false,
      isInitVideo: false,
      player: null,
      isInnerIframe: false,
      centerLoading: false, // 创作者中心 loading
    };
  },
  computed: {
    /**
     *
     */
    playsinline() {
      // this.playerOptions.muted = false; // 播放之后关闭静音
      return this.isIos();
    },
    token() {
      return getToken();
    },
    /**
     * 文件类型是否支持预览
     */
    isCanPreview() {
      return (
        this.file &&
        [
          "doc",
          "ppt",
          "mp4",
          "mov",
          "docx",
          "pptx",
          "pdf",
          "PDF",
          "xls",
          "xlsx",
          "mp3",
          "MP3",
          "wav",
          "WAV",
          "flac",
          "FLAC",
          "aac",
          "AAC",
          "m4a",
          "M4A",
        ].indexOf(this.fileExtension) !== -1
      );
    },
    // ImgUrl(){
    //   return this.getImgUrl();
    // },
    /**
     * 文件地址
     */
    // fileUrl() {
    // },
    /**
     * 文件类型，文件后缀名
     */
    fileExtension() {
      return (
        (this.file && this.$utils.getExtension(this.file.resourceUrl)) || ""
      );
    },
    /**
     * 是word文件
     */
    isWordFile() {
      return ["doc", "docx"].indexOf(this.fileExtension) !== -1;
    },
    /**
     * 是否可以打印
     */
    isPrint() {
      var params = this.$route.params;

      // 需求变更
      return params && ["sk-kqyx", "sk-zy"].indexOf(params.type) !== -1;
    },
    isAllInit() {
      return this.isInitVideo && this.isFinish;
    },
  },
  watch: {
    // 判断是否都加载好了
    isAllInit(val) {
      if (val) {
        this.initVideo();
      }
    },
    async file(newVal, oldVal) {
      if (!this.isCanPreview) {
        if (
          ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG"].indexOf(
            this.fileExtension
          ) !== -1
        ) {
          this.getImgUrl();
        }
        return;
      }
      if (!this.needCheckResource) return; // 模拟数据不需要检查资源
      if (!this.file) return;
      if (
        this.$route.params &&
        ["bk-bksp", "bk-ktsl", "bk-ktzl"].indexOf(this.$route.params.type) !==
          -1 &&
        [
          "mp4",
          "mov",
          "mp3",
          "MP3",
          "wav",
          "WAV",
          "flac",
          "FLAC",
          "aac",
          "AAC",
          "m4a",
          "M4A",
        ].indexOf(this.fileExtension) !== -1
      ) {
        // this.getVideoUrl();
        this.initbutton = false;
        this.loading = false;
        this.isAuth = false;
        this.isInitVideo = false;
        let checkCourseAuth = await this.toCheckBSKAuth();

        if (checkCourseAuth) {
          checkResourceAuth({
            resourceId: this.file.resourceId,
            courseId: this.courseId,
            knowledgeId: this.id,
            originTenantId: this.$route.query.originTenantId,
          })
            .then(({ data }) => {
              this.file.fileName = data.resourceName;
              console.log(this.file.fileName);
              this.getVideoUrl();
              if (data.auth === true) {
                this.isAuth = true;
                this.wpsEditUrl = data.wpsEditUrl;

                this.$nextTick(() => {
                  // var video = this.$refs.video;
                  // if (this.vedioLocation) {
                  // // 定位视频播放位置
                  // video.currentTime = +this.vedioLocation;
                  this.isInitVideo = true;
                });
                this.$emit("fileChangedInC", newVal, oldVal);
              } else {
                this.$router.push({
                  name: "Resource",
                });
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        let thiswpsstate = "read";
        if (this.isWantedit) {
          this.pptreadoredit = true;
        } else {
          this.pptreadoredit = false;
          thiswpsstate = "write";
          this.isWantedit = true;
        }
        this.initbutton = false;
        this.floorstate = false;
        this.nextstate = false;
        this.loading = false;
        this.isAuth = false;
        let canWps = true;

        let checkCourseAuth = await this.toCheckBSKAuth();

        if (checkCourseAuth) {
          await checkResourceAuth({
            resourceId: this.file.resourceId,
            courseId: this.courseId,
            knowledgeId: this.id,
            originTenantId: this.$route.query.originTenantId,
          })
            .then(({ data }) => {
              this.file.fileName = data.resourceName;
              if (data.auth === true) {
                // this.isAuth = true;
                // this.wpsEditUrl = data.wpsEditUrl;
                this.$nextTick(() => {
                  var video = this.$refs.video;
                  if (this.vedioLocation) {
                    // 定位视频播放位置
                    video.currentTime = +this.vedioLocation;
                  }
                });
                this.$emit("fileChangedInC", newVal, oldVal);
              } else {
                this.$router.push({
                  name: "Resource",
                });
              }
            })
            .catch(() => {
              canWps = false;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          canWps = false;
        }

        // 若果检查资源失败，不做 wps 预览
        if (!canWps) {
          return;
        }
        if (
          [
            "/classroomWps/bk-ja",
            "/classroomWps/bk-kj",
            "/classroomWps/sk-kqyx",
            "/classroomWps/sk-kj",
            "/classroomWps/sk-zy",
            "/classroomWps/bk-ktzl",
          ].indexOf(this.$route.path) !== -1 &&
          [
            "mp4",
            "mov",
            "mp3",
            "MP3",
            "wav",
            "WAV",
            "flac",
            "FLAC",
            "aac",
            "AAC",
            "m4a",
            "M4A",
          ].indexOf(this.fileExtension) === -1
        ) {
          if (this.wpsSdkVersion === 1) {
            webofficeUrl({
              _w_file_id: this.file.resourceId,
              // _w_file_code:
              _w_file_format: this.file.format.toLowerCase(),
              _w_permission: thiswpsstate,
              _w_file_version: this.file.version,
              _w_file_code: this.courseId + "-" + this.file.resourceType,
              _w_file_source: "bk",
            }).then((res) => {
              if (thiswpsstate == "write") {
                this.isAuth = true;
                this.wpsEditUrl = res.data.wpsUrl;
                this.wpstoken = res.data.token;
                // this.wpsEditUrl ="https://wwo.wps.cn/office/p/5?_w_fileid=5&_w_appid=4527d895d700434eb997857888ecddc3&_w_signature=hLKq9%2BILPx3wr1h9Zvmu622rhwc%3D"
              } else if (thiswpsstate == "read") {
                this.isAuth = true;
                this.wpsReadUrl = res.data.wpsUrl;
                this.wpsReadtoken = res.data.token;
              }
            });
          } else {
            // wps V2 版本获取参数
            let params = {
              _w_file_code: this.courseId + "-" + this.file.resourceType,
              _w_file_format: this.file.format.toLowerCase(),
              _w_file_id: this.file.resourceId,
              _w_file_source: "bk",
              _w_file_version: this.file.version,
              _w_isUpdate: thiswpsstate == "write" ? 1 : 0,
            };
            getWPSParam(params)
              .then((result) => {
                console.log("result=======", result);
                this.isAuth = true;
                this.wpsReadtoken = this.token;
                if (thiswpsstate == "write") {
                  this.wpsEditParams = result.data;
                  // this.wpsEditUrl ="https://wwo.wps.cn/office/p/5?_w_fileid=5&_w_appid=4527d895d700434eb997857888ecddc3&_w_signature=hLKq9%2BILPx3wr1h9Zvmu622rhwc%3D"
                } else if (thiswpsstate == "read") {
                  this.isAuth = true;
                  this.wpsReadParams = result.data;
                }
              })
              .catch((err) => {});
          }
        }
      }
    },
  },
  created() {
    document.addEventListener("keydown", this.onF11FullScreen);
    initBrowserPrompt();
  },
  mounted() {
    // this.isMobile();
    this.isInnerIframe = window.self !== window.top;
    if (this.file) console.log(this.colorType, this.file.category, "colorType");
  },
  beforeUpdate() {
    this.isMobile();
  },
  beforeDestroy() {
    this.setVideoPlayer(null);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onF11FullScreen);
  },
  methods: {
    ...mapActions(["setVideoPlayer"]),
    async showPagePreview() {
      let _this = this;
      await _this.wpsConfig.ready();
      const app = _this.wpsConfig.Application;
      app.ActivePresentation.SlideShowSettings.SetMiniThumbnailVisible(
        this.pagePreview
      );
    },
    async toCheckBSKAuth() {
      try {
        // 先校验课程权限
        const response = await checkBSKAuth({
          courseId: this.courseId,
          originTenantId: this.$route.query.originTenantId,
        });

        if (response && response.data) {
          return true;
        } else {
          this.$router.push({
            name: "Auth",
          });
          return false;
        }
      } catch (error) {
        this.$router.push({
          name: "Auth",
        });
        return false;
      }
    },

    changewpscurryPage(data, type) {
      if (type == 1) {
        console.log("456465");
        this.wpscurryPage = data;
      }
      if (type == 2) {
        console.log("456465");
        this.wpscount = data;
      }
    },
    // 获取到视频地址之后的加载
    initVideo() {
      console.log(this.fileUrl);
      console.log(document.getElementById("player-con"));
      this.$nextTick(() => {
        this.player = new Aliplayer(
          {
            id: "player-con", // 播放器外层容器的dom元素id
            source: this.fileUrl, // 视频播放地址url
            width: "100%",
            height: "100%",
            autoplay: false,
            isLive: false,
            rePlay: false,
            playsinline: true,
            components: [
              {
                name: "RateComponent",
                type: AliPlayerComponent.RateComponent,
              },
            ],
            preload: true,
            language: "zh-cn",
            controlBarVisibility: "hover",
            useH5Prism: true, // 指定使用H5播放器
            skinLayout: [
              {
                name: "bigPlayButton",
                align: "cc",
                x: 30,
                y: 80,
              },
              {
                name: "errorDisplay",
                align: "tlabs",
                x: 0,
                y: 0,
              },
              {
                name: "infoDisplay",
              },
              {
                name: "controlBar",
                align: "blabs",
                x: 0,
                y: 0,
                children: [
                  { name: "progress", align: "blabs", x: 0, y: 44 }, // 播放进度条
                  { name: "playButton", align: "tl", x: 15, y: 12 }, // 播放开始暂停按钮
                  { name: "timeDisplay", align: "tl", x: 10, y: 7 }, // 播放视频时间
                  {
                    name: "fullScreenButton", // 全屏按钮
                    align: "tr",
                    x: 10,
                    y: 10,
                  },
                  {
                    name: "volume", // 音量
                    align: "tr",
                    x: 5,
                    y: 10,
                  },
                ],
              },
            ],
          },
          function (player) {
            console.log("The player is created");
          }
        );
        this.player.on("requestFullScreen", this.fullScreenHandle);
        console.log(
          " this.player.getStatus()============",
          this.player.getStatus()
        );
        this.setVideoPlayer(this.player);
      });
    },
    fullScreenHandle() {
      console.log("fullScreenHandle================");
    },
    // 创作者中心
    onShowCreator() {
      this.editVisible = true;
      this.getOssPolicyUrl();
    },

    // 获取 uploadUrlParams 阿里云上传参数
    getOssPolicyUrl() {
      // let params = {
      //   fileName: file.name,
      // };
      // return getOssPolicyV2().then((ele) => {
      // 更改为obs直传
      return getObsPolicy({ source: "front" }).then((ele) => {
        if (ele.success) {
          this.uploadUrlParams = ele.data;
          // console.log("this.uploadUrlParams",this.uploadUrlParams);
        } else {
          this.$message.warning(this.$t("el.common.errorServer"));
        }
      });
    },
    // 通过下载接口获取图片地址
    async getImgUrl() {
      await axios
        .get(
          `/obs/info/download?objectName=${this.file.resourceUrl}&displayName=${this.file.resourceUrl}&isDownload=0`
        )
        .then((res) => {
          console.log("res", res.data);
          let url = res.data;
          // url.indexOf("http://") > -1 && (url = url.replace("http://", "//"));
          // url.indexOf("https://") > -1 && (url = url.replace("https://", "//"));

          this.imgUrl = url;

          console.log(this.imgUrl, "45454545");
        });
    },
    /**
     * 编辑状态信息查询
     */
    async setpptreadoredit() {
      let _this = this;
      if (["ppt", "pptx", "PPT", "PPTX"].indexOf(_this.fileExtension) !== -1) {
        await _this.wpsConfig.ready();
        const app = _this.wpsConfig.Application;
        await app.ActivePresentation.SlideShowWindow.View.Exit();
      }
      _this.initbutton = false;
      let thiswpsstate = "write";
      console.log(_this.file, " _this.file");

      let checkCourseAuth = await this.toCheckBSKAuth();
      if (checkCourseAuth) {
        checkResourceAuth({
          resourceId: _this.file.resourceId,
          courseId: _this.courseId,
          knowledgeId: _this.id,
          originTenantId: this.$route.query.originTenantId,
        })
          .then(({ data }) => {
            if (data.auth === true) {
              _this.file.fileName = data.resourceName;
              _this.isAuth = true;
              // _this.wpsEditUrl = data.wpsEditUrl;
              _this.$nextTick(() => {
                var video = _this.$refs.video;
                if (_this.vedioLocation) {
                  // 定位视频播放位置
                  video.currentTime = +_this.vedioLocation;
                }
              });

              if (this.wpsSdkVersion === 1) {
                webofficeUrl({
                  _w_file_id: _this.file.resourceId,
                  _w_file_format: _this.file.format.toLowerCase(),
                  _w_permission: thiswpsstate,
                  _w_file_version: _this.file.version,
                  _w_file_code: this.courseId + "-" + _this.file.resourceType,
                  _w_file_source: "bk",
                }).then((res) => {
                  _this.wpsEditUrl = res.data.wpsUrl;
                  _this.wpstoken = res.data.token;
                  _this.pptreadoredit = false;
                  // this.wpsEditUrl ="https://wwo.wps.cn/office/p/5?_w_fileid=5&_w_appid=4527d895d700434eb997857888ecddc3&_w_signature=hLKq9%2BILPx3wr1h9Zvmu622rhwc%3D"
                });
              } else {
                // wps V2 版本获取参数
                let params = {
                  _w_file_code: this.courseId + "-" + _this.file.resourceType,
                  _w_file_format: _this.file.format.toLowerCase(),
                  _w_file_id: _this.file.resourceId,
                  _w_file_source: "bk",
                  _w_file_version: _this.file.version,
                  _w_isUpdate: thiswpsstate == "write" ? 1 : 0,
                };
                getWPSParam(params)
                  .then((result) => {
                    console.log("result=======", result);
                    this.isAuth = true;
                    this.wpsReadtoken = this.token;
                    this.wpsEditParams = result.data;
                    _this.pptreadoredit = false;
                    // this.wpsEditUrl ="https://wwo.wps.cn/office/p/5?_w_fileid=5&_w_appid=4527d895d700434eb997857888ecddc3&_w_signature=hLKq9%2BILPx3wr1h9Zvmu622rhwc%3D"
                  })
                  .catch((err) => {});
              }
            } else {
              _this.$router.push({
                name: "Resource",
              });
            }
          })
          .finally(() => {
            _this.loading = false;
          });
      }
    },
    /**
     * 初始化状态
     */
    setinitdata(type) {
      console.log(456456456456464646465);
      this.initbutton = type;
    },
    /**
     * 改变按钮状态
     */
    setbuttonstate(type1, type2) {
      this.floorstate = type1;
      this.nextstate = type2;
    },
    async setfloorbutton() {
      let _this = this;
      await _this.wpsConfig.ready();
      const app = _this.wpsConfig.Application;
      // 演示文稿对象
      const presentation = await app.ActivePresentation;
      // 幻灯片对象
      const slides = await presentation.Slides;
      // 获取总页数
      const count = await slides.Count;
      // 获取当前页码
      const wpscurryPage = await app.ActivePresentation.SlideShowWindow.View
        .Slide.SlideIndex;
      _this.nextstate = true;
      if (wpscurryPage == 1) {
        this.floorstate = false;
      }
      if (wpscurryPage > 1) {
        if (wpscurryPage == 2) {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage - 1
          );
          this.floorstate = false;
        } else {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage - 1
          );
          this.floorstate = true;
        }
      }
    },
    /**
     * 下一页
     */
    async setbutton() {
      let _this = this;
      await _this.wpsConfig.ready();
      const app = _this.wpsConfig.Application;
      // 演示文稿对象
      const presentation = await app.ActivePresentation;
      // 幻灯片对象
      const slides = await presentation.Slides;
      // 获取总页数
      const count = await slides.Count;
      // 获取当前页码
      const wpscurryPage = await app.ActivePresentation.SlideShowWindow.View
        .Slide.SlideIndex;
      _this.floorstate = true;
      if (count == wpscurryPage) {
        this.nextstate = false;
      }
      if (count > wpscurryPage) {
        if (count == wpscurryPage + 1) {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage + 1
          );
          this.nextstate = false;
        } else {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage + 1
          );
          this.nextstate = true;
        }
      }
    },
    /**
     * 打印按钮
     */
    async printClick() {
      let _this = this;
      await _this.wpsConfig.ready();
      const app = _this.wpsConfig.Application;
      const printbtn = await app.CommandBars("TabPrintPreview");
      printbtn.Execute();
    },
    /**
     * vue-video-player ios版本配置
     */
    isMobile() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (
        /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          sUserAgent
        ) &&
        (["mp4"].indexOf(this.fileExtension) !== -1 ||
          ["mov"].indexOf(this.fileExtension) !== -1)
      ) {
        // 移动端页面
        this.testdata = this.$t("el.common.mobilePreviewError");
      } else {
        // pc端页面
        this.testdata = "";
      }

      let ua = navigator.userAgent;
      var isSafari = ua.indexOf("Safari") != -1 && ua.indexOf("Version") != -1;
      var isIphone = ua.indexOf("iPhone") != -1 && ua.indexOf("Version") != -1;
      var isIPad = isSafari && !isIphone && "ontouchend" in document;

      if (
        !/iphone|ios|ipad|android|mobile/i.test(
          navigator.userAgent.toLowerCase()
        ) &&
        !isIPad
      ) {
        this.testdata = "";
      } else {
        if (
          ["mp4"].indexOf(this.fileExtension) !== -1 ||
          ["mov"].indexOf(this.fileExtension) !== -1
        ) {
          this.testdata = this.$t("el.common.mobilePreviewError");
        }
      }
    },
    /**
     * 视频加载成功
     */
    onVideoCanplay() {
      // var video = this.$refs.video;
      // if (this.vedioLocation) { // 定位视频播放位置
      // video.currentTime = this.vedioLocation;
      // }
      // console.log(video);
    },
    change(val) {},
    async getVideoUrl() {
      console.log(this.file, 111);
      // 判断fileName 是不是视频格式 mp4 mov
      let fileLength = this.file.fileName.lastIndexOf(".");
      let fileType = this.file.fileName.substring(fileLength + 1);
      let isVideo = false;
      if (
        ["mp4", "MP4"].indexOf(fileType) > -1 ||
        ["mov", "MOV"].indexOf(fileType) > -1
      ) {
        isVideo = true;
      } else {
        isVideo = false;
      }
      this.isFinish = false;

      let fileResourceUrl = this.file.resourceUrl;
      const OBSDIR = process.env.VUE_APP_OBS_VOD_VIDEO_DIR;
      if (isVideo) {
        if (!fileResourceUrl.includes(OBSDIR)) {
          let prefix = OBSDIR;
          if (!fileResourceUrl.startsWith("/")) {
            prefix = `${OBSDIR}/`;
          }
          fileResourceUrl = prefix + fileResourceUrl;
        }
      }
      await axios
        .get(
          `/obs/info/download?objectName=${fileResourceUrl}&displayName=${this.file.fileName}&isDownload=0`
        )
        .then((res) => {
          let url = res.data;
          console.log("url=====", url);
          console.log("indexOf", url.indexOf("http://") > -1);
          // url.indexOf("http://") > -1 && (url = url.replace("http://", "//"));
          // url.indexOf("https://") > -1 && (url = url.replace("https://", "//"));
          this.fileUrl = url;
          this.isFinish = true;
        });
      // return res
    },
    centerSubmit() {
      this.centerLoading = true;
      if (
        ["mp4"].indexOf(this.fileExtension) !== -1 ||
        ["mov"].indexOf(this.fileExtension) !== -1
      ) {
        this.submitData.vedioLocation = Math.floor(
          document.getElementById("videoPlayer").currentTime
        );
      } else {
        this.submitData.vedioLocation = 0;
      }
      var check = this.$utils.check(this.submitData, [
        {
          key: "description",
          require: true,
          msg: this.$t("el.common.cannotEmpty"),
        },
      ]);
      this.submitData.resourceId = this.file && this.file.resourceId;
      this.submitData.knowledgeId = this.id;

      if (check === 1) {
        addCreatorCenter(this.submitData)
          .then(({ data }) => {
            this.$message({
              type: "success",
              message: this.$t("el.common.submitSuccessfully"),
            });
            this.submitData.description = "";
            this.editVisible = false;
          })
          .catch(() => {})
          .finally(() => {
            this.centerLoading = false;
          });
      } else {
        this.centerLoading = false;
        this.$message({
          type: "error",
          message: check,
        });
      }
    },
    /**
     * 检测按f11全屏
     */
    onF11FullScreen(e) {
      if (e && e.keyCode == 122) {
        // 捕捉F11键盘动作
        e.preventDefault(); // 阻止F11默认动作
      }
    },
    exitFullScreen() {
      if (this.isInnerIframe) {
        window.parent.postMessage(
          JSON.stringify({
            type: "AIYKT",
            data: {
              type: "FULL_SCREEN_PREPARE",
              fullScreen: false,
            },
          }),
          "*"
        );
        this.toggle();
      } else {
        this.toggle();
      }
    },

    toggle() {
      this.$refs.fullscreen && this.$refs.fullscreen.toggle();
      // this.fullscreen = !this.fullscreen // deprecated
    },
    onFullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
      this.$emit("onFullscreenChange", fullscreen);
    },
    /**
     * 播放回到officeOnline
     */
    async playOfficeOnline() {
      const response = await this.wpsConfig.save();
      this.$emit("changeIsEdit", false);
    },
  },
};
</script>

<style lang="less" scoped>
.pages {
  z-index: 999;
  position: fixed;
  bottom: 30px;
  right: 12px;
  width: 98px;
  height: 28px;
  border-radius: 5px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(66, 94, 195, 0.4);
  box-shadow: 0px 3px 10px 0px rgba(66, 94, 195, 0.4);
  padding: 4px 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  .page-back,
  .page-next {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 24px;
    color: #5a53f5;
  }
  .page-no-back {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 24px;
    color: #999;
    cursor: not-allowed;
  }
  .page-no-next {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 24px;
    color: #999;
    cursor: not-allowed;
  }
  .page-numbers {
    font-size: 14px;
    color: #5a53f5;
    line-height: 28px;
  }
}
/deep/ .web-office-iframe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}
/deep/ .video-player,
/deep/ .video-js {
  width: 100%;
  height: 100%;
}
/deep/ .video-js .vjs-control {
  z-index: 1;
}
/deep/ .video-js .vjs-big-play-button {
  display: none;
}
.file-preview {
  height: 100%;
  background: #393a3d;

  .doc,
  .ppt,
  .video {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .video {
    // video {
    // width: 100%;
    // // height: 560px;
    // height: 100%;
    // // position: absolute;
    // // top: 50%;
    // // left: 50%;
    // // transform: translate(-50%, -50%);
    // }
    // video::-internal-media-controls-download-button {
    // display:none;
    // }
    // video::-webkit-media-controls-enclosure {
    // overflow:hidden;
    // }
    // video::-webkit-media-controls-panel {
    // width: calc(100% + 30px);
    // }
  }
  .ppt {
    position: relative;
    font-size: 0;
    &.mask {
    }
    .mask-wps-after {
      position: absolute;
      right: 278px;
      bottom: 6px;
      width: 27px;
      height: 20px;
      background: #f2f4f7;
      z-index: 1;
    }
    &.mask-show {
      // &::after {
      // content: "";
      // display: block;
      // position: absolute;
      // right: 1px;
      // bottom: 1px;
      // width: 37px;
      // height: 20px;
      // background: #d24726;
      // }
      // &::before {
      // content: "";
      // display: block;
      // position: absolute;
      // left: 0px;
      // top: 30px;
      // width: 0px;
      // height: 22px;
      // background: #393a3d;
      // }
    }
    .wps-play {
      position: absolute;
      z-index: 9;

      &.before {
        background: #f2f4f7;
        width: 137px;
        height: 34px;
        padding-top: 6px;
        padding-left: 37px;
        box-sizing: border-box;
        right: 2px;
        top: 6px;

        div {
          width: 40px;
          height: 20px;
          background: #fff;
          border: 1px solid #eee;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            width: 15px;
            height: auto;
          }
        }
      }
      &.after {
        position: absolute;
        z-index: 9;
        right: 205px;
        bottom: 6px;
        width: 27px;
        height: 20px;
        background: #f2f4f7;
        box-sizing: border-box;
        padding-top: 4px;
        padding-left: 5px;
        img {
          width: 15px;
          height: auto;
          cursor: pointer;
        }
      }
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .doc {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .not-data {
    text-align: center;
    color: rgba(250, 250, 250, 0.8);
    padding-top: 260px;
    line-height: 28px;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 600;
  }
  // 创作者中心
  .creator-center__btn {
    width: 84px;
    height: 48px;
    background: url("~@/assets/images/pic_create.png") no-repeat center center;
    background-size: contain;
    position: absolute;
    bottom: 24px;
    right: 16px;
    cursor: pointer;
    &.doc,
    &.docx,
    &.ppt,
    &.pptx {
      bottom: 80px;
    }
    &.mp4 {
      bottom: 70px;
    }
    &.is-print {
      right: 28px;
      bottom: 220px;
    }
  }
  .creator-center__btn_en {
    background: url("~@/assets/images/pic_create_en.png") no-repeat center
      center;
    background-size: contain;
  }
  .editdialog {
    &-header {
      text-align: left;
      position: relative;

      &-icon {
        position: absolute;
        top: 2px;
        left: 10px;
        width: 40px;
        height: 59px;
      }
      &-title {
        font-size: 20px;
        // font-family: "MicrosoftYaHei-Bold", "MicrosoftYaHei";
        font-weight: bold;
        color: rgba(68, 68, 68, 1);
        line-height: 26px;
        margin: 0 0 11px 69px;
        padding-top: 16px;
      }
      &-stitle {
        font-size: 15px;
        // font-family: "MicrosoftYaHei";
        color: rgba(100, 108, 149, 1);
        line-height: 20px;
        margin: 0 0 -2px 69px;
      }
    }
    &-footer {
      &-btn {
        margin: -4px 18px 22px;
        width: 84px;
      }
    }
  }
}
// 加载动画
// .circular-load {
// position: relative;
// width: 56px;
// height: 56px;
// span {
// display: block;
// width: 10px;
// height: 10px;
// position: absolute;
// transform-origin: center 28px;
// left: 23px;
// top: 0;
// &::after {
// display: block;
// content: '';
// width: 10px;
// height: 10px;
// border-radius: 50%;
// background-color: #646C95;
// }
// }
// .loop(@i, @n:@i+1) when (@i < 8) {
// span:nth-of-type(@{n}) {
// transform: rotate((@i*45deg));
// transition: all;
// &::after {
// animation: load 0.8s infinite (@i*0.1s);
// }
// }
// .loop(@i + 1);
// }
// .loop(0);
// }
// @keyframes load {
// 0% {
// transform: scale(1);
// }
// 12.5% {
// transform: scale(1);
// }
// 25% {
// transform: scale(1);
// }
// 37.5% {
// transform: scale(1);
// }
// 50% {
// transform: scale(1);
// }
// 62.5% {
// transform: scale(1);
// }
// 75% {
// transform: scale(.8);
// }
// 87.5% {
// transform: scale(.6);
// }
// 100% {
// transform: scale(.4);
// }
// }
// .hide-edit {
// position: absolute;
// height: 22px;
// width: 36px;
// bottom: 0px;
// right: 75px;
// background-color: #d24726;
// }
.videotips {
  background: #ccc;
  line-height: 16px;
  font-size: 14px;
  padding: 2px 10px;
}
.setbutton {
  z-index: 10;
  position: fixed;
  bottom: 30px;
  right: calc(50% - 352px);
  /deep/ .el-button--primary {
    background: #faf9ff;
    color: @themeBlue;
  }
  /deep/ .el-button--primary:hover {
    background: @themeBlue;
    color: #ffffff;
    border-color: @themeBlue;
  }
  /deep/ .is-disabled {
    color: #b3b3b3;
  }
  /deep/ .is-disabled:hover {
    color: #b3b3b3;
    background: #ffffff;
    border: 1px solid #b3b3b3;
  }
}
.sksetbutton {
  z-index: 10;
  position: fixed;
  bottom: 30px;
  right: calc(50% - 352px);
  /deep/ .el-button--primary {
    background: #faf9ff;
    color: @themeBlue;
  }
  /deep/ .el-button--primary:hover {
    background: @themeBlue;
    color: #ffffff;
    border-color: @themeBlue;
  }
  /deep/ .is-disabled {
    color: #b3b3b3;
  }
  /deep/ .is-disabled:hover {
    color: #b3b3b3;
    background: #ffffff;
    border: 1px solid #b3b3b3;
  }
}
.setfloorbutton {
  z-index: 10;
  position: fixed;
  bottom: 30px;
  right: calc(50% - 252px);
  cursor: not-allowed;
  /deep/ .el-button--primary {
    background: #faf9ff;
    color: @themeBlue;
  }
  /deep/ .el-button--primary:hover {
    background: @themeBlue;
    color: #ffffff;
    border-color: @themeBlue;
  }
  /deep/ .is-disabled {
    color: #b3b3b3;
  }
  /deep/ .is-disabled:hover {
    color: #b3b3b3;
    background: #ffffff;
    border: 1px solid #b3b3b3;
  }
}
.printButton {
  z-index: 10;
  position: fixed;
  top: 70px;
  right: 28px;
  // bottom: 30px;
  // right: calc(50% - 252px);
  cursor: not-allowed;
  /deep/ .el-button--primary {
    background: #faf9ff;
    color: @themeBlue;
  }
  /deep/ .el-button--primary:hover {
    background: @themeBlue;
    color: #ffffff;
    border-color: @themeBlue;
  }
  /deep/ .is-disabled {
    color: #b3b3b3;
  }
  /deep/ .is-disabled:hover {
    color: #b3b3b3;
    background: #ffffff;
    border: 1px solid #b3b3b3;
  }
}
.sksetfloorbutton {
  z-index: 10;
  position: fixed;
  bottom: 30px;
  right: calc(50% - 252px);
  cursor: not-allowed;

  /deep/ .el-button--primary {
    background: #faf9ff;
    color: @themeBlue;
  }
  /deep/ .el-button--primary:hover {
    background: @themeBlue;
    color: #ffffff;
    border-color: @themeBlue;
  }
  /deep/ .is-disabled {
    color: #b3b3b3;
  }
  /deep/ .is-disabled:hover {
    color: #b3b3b3;
    background: #ffffff;
    border: 1px solid #b3b3b3;
  }
}
.fullset {
  right: calc(50% - 89px);
}
.fullfloorset {
  right: calc(50% + 15px);
}
.whiteboard-exit {
  // position: absolute;
  // top: 14px;
  // right: 34px;
  font-size: 0;
  position: fixed;
  top: 10px;
  right: 34px;
  font-size: 0;
  z-index: 100;
  right: 28px;
  display: flex;
  cursor: pointer;
  opacity: 0.6;
  height: 30px;
  padding: 2px 4px;
  background: #5b5b5b;

  img {
    width: 24px;
    height: 24px;

    &:first-child {
      margin-top: 3px;
    }
  }
  .exit-info {
    color: #bbb;
    font-size: 16px;
    line-height: 26px;
    margin-left: 4px;
    margin-top: 2px;
  }
  &:hover {
    opacity: 1;
  }
}
// 暂无数据 start
.no-data_box {
  width: 100%;
  height: 100%;
  background: #f6f8fc;
  text-align: center;
  padding-top: 40px;
  .no-data-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  &.no-data_sk {
    .no-data_btn {
      background: @themeBlue;
    }
  }
  .no-data_img {
    width: 325px;
  }
  .no-data_text {
    font-size: 18px;
    font-weight: 600;
    color: rgba(100, 108, 149, 0.25);
    line-height: 25px;
    letter-spacing: 1px;
    // margin-top: 28px;
    margin-bottom: 44px;
  }
  .no-data_icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    line-height: 38px;
    vertical-align: middle;
    cursor: pointer;
    .gen-icon("icon_list_upload2@2x");
  }
  .no-data_btn {
    width: 296px;
    height: 38px;
    background: @themeBlue;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    label {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 38px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
.img-box {
  overflow: auto;
  img {
    width: 80%;
  }
}
</style>
